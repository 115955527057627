// @mixin bi {

//   // usage:
//   // padding-#{$from}: 3px;
//   // padding-#{$to}: 3px;
//   // text-align: #{$from}: 3px;
//   // text-direction: #{$dir};

// 	$from: right !global;
// 	$to: left !global;
// 	$dir: rtl !global;
// 	$susy: (flow: rtl) !global;

// 	@at-root {
// 		[dir='rtl'] {
// 			@content;
// 		}
// 	}

// 	$from: left !global;
// 	$to: right !global;
// 	$dir: ltr !global;
// 	$susy: (flow: ltr) !global;

// 	@at-root {
// 		[dir='ltr'] {
// 			@content;
// 		}
// 	}

// }

@function rtlfont($px) {
  @return $px * 1.4px;
}

@mixin phone-only {
  @media (max-width: 766px) { @content; }
}
@mixin tablet-portrait {
  @media (min-width: 767px) { @content; }
}
@mixin tablet-landscape {
  @media (min-width: 900px) { @content; }
}
@mixin small-desktop {
  @media (min-width: 1200px) { @content; }
}
@mixin medium-desktop {
  @media (min-width: 1400px) { @content; }
}
@mixin big-desktop {
  @media (min-width: 1680px) { @content; }
}
@mixin x-big-desktop {
  @media (min-width: 1921px) { @content; }
}

@mixin border-radius($px) {
  -webkit-border-radius:$px;
  -moz-border-radius:$px;
  -ms-border-radius:$px;
  border-radius:$px;
}

@mixin box-shadow($string) {
  -moz-box-shadow:    $string;
  -webkit-box-shadow: $string;
  box-shadow:         $string;
}

// @mixin postcssrtl-prepend($value) {
//   /*rtl:prepend:$value,*/
// }

@mixin button {
  font-family: $Gotham-Book;
  font-weight: 500;
  background-color: $black;
  color: $white;
  text-align: center;
  padding: .6em 5em;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;

  @include border-radius(22px);

  @include tablet-portrait {
    max-width: 228px;
  }

  @include small-desktop {
    max-width: 289px;
    @include border-radius(27px);
  }

  .glyphicon {
    display: none;
  }

  &:hover {
    background-color: #707070;
  }

}

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: alpha(opacity=$opacity-ie);
}


// Typogrpahy
@mixin heading1 {
  font-family: $MinionPro-Regular;
  color: $black;
  border: 0;
  font-size: 3.3em;
  letter-spacing: -.04em;
  line-height: 1em;
  font-weight: normal;
  border: none;
  margin-bottom: 35px;
  margin-top: 0px;
  padding-bottom: 0;
  @include tablet-portrait {
    font-size: 4em;
  }
  @include small-desktop {
  }
}

@mixin heading2 {
  font-family: $Gotham-Medium;
  font-weight: 500;
}
