// What is Safar Page

/* START What is safar subpage */

#Section-Intro,
#Section-Start {

  .fp-slidesNav,
  .fp-controlArrow,
  article header,
  article footer,
  .field-label {
    display: none;
  }

}

#Section-Start {

  .whatisslide {
    background: url('../images/ribbon-what-safar.svg') no-repeat center center;
    background-size: auto 60%;

    @include tablet-portrait {
      background-size: 100%;
    }

    article {
      width: 100%;
      @include tablet-portrait {
        width: 100%;
      }
      @include tablet-landscape {
        width: 70%;
      }
      @include small-desktop {
        width: 60%;
      }
      @mixin medium-desktop {
        width: 70%;
      }
      @include big-desktop {
        width: 70%;
      }
    }

    h1 {
      font-size: 2.3em;
      margin-bottom: 7px;
      padding-left: 6%;
      text-align: left;
    }

    #get-started-link-container {
      font-family: $Gotham-Medium;
      font-weight: 500;
      margin-top: 35px;
      text-align: center;

      @include tablet-portrait {
        margin-top: 60px;
      }
      @include tablet-landscape {
        margin-top: 15px;
      }
      @include small-desktop {
        margin-top: 20px;
      }
      @include big-desktop {
        margin-top: 60px;
      }

      a {
        text-decoration: underline;
        color: $black;
        font-size: 1.6em;

        @include tablet-portrait {
          font-size: 1.8em;
        }

      }
    }
  }

}

#fullpage #Section-Intro {

  .introslide .paragraphs-column {
    width: 75%;
    text-align: left;
    border: none;

    @include tablet-portrait {
      width: 50%;
    }
  }

  h1 {
    margin-bottom: 0;
  }

  p {
    line-height: 1.4em;
    margin: 0.7em 0;
  }

  @include tablet-landscape {
    .paragraphs-column {
      flex-grow: 0.5;
    }
  }

  a#get-started-link {
    display: block;
    background: url('../images/arrow-down-long.svg') no-repeat left bottom;
    background-size: 12px auto;
    color: #000000;
    text-decoration: none;
    height: 110px;
    position: absolute;

    @include tablet-portrait {
      height: 140px;
      background-size: 15px auto;
    }
  }



}

/* END What is safar subpage */

// Brilliant


// RTL Frontpage
html[dir="rtl"] {

  .page-what-is-safar {
    .page-header {
      text-align: right;
    }
  }

  #Section-Start {

    .whatisslide {
      /* TODO: Flip background image */
      background: url('../images/ribbon-what-safar_rtl.svg') no-repeat center center;
      background-size: auto 60%;

      @include tablet-portrait {
        background-size: 100%;
      }

      h1 {
        font-size: 1.5em;
        padding-right: 8%;
        text-align: right;
        font-family: $estedad-Medium;
      }
    }
  }

  #fullpage #Section-Intro {

    .introslide .paragraphs-column {
      text-align: right;
    }

    a#get-started-link {
      background: url('../images/arrow-down-long.svg') no-repeat right bottom;
    }

  }

}
