// Manage Steps styles

// .view-id-manage_steps {



//   // .views-field-edit-node a {
//   //   padding: 10px 15px;
//   //   font-size: 13px;
//   //   letter-spacing: 0.5px;
//   //   background-color: $pink;
//   //   color: $white;
//   //   text-transform: capitalize;
//   //   border-radius: 2px;
//   //   -moz-border-radius: 2px;
//   //   -webkit-border-radius: 2px;
//   // }

//   .views-field-field-duration,
//   .views-field-field-related-outline,
//   .views-field-field-related-video,
//   .views-field-edit-node {
//     text-align: center;
//   }

// }

// // RTL
// html[dir="rtl"] {

//   #views-form-manage-steps-page {
//     .views-field-title-field {
//       padding-left: 0;
//       text-align: right;

//       .tabledrag-handle {
//         margin-right: 0;
//         margin-left: 10px;
//         height: 29px;
//         padding: 4px 0;
//       }
//     }
//   }

// }
