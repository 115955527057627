/* New Sections */

#html-learn-more-, #html-how-to-use-, #html-the-steps-{
    height: auto;
}

.logged-in .round-button.black.start-safar{
    display: none;
}

/* Lean More Section */

.page-learn-more {    
    color: #000;
    background-attachment: fixed;

    #navbar {
        position: fixed;
    }

    .main-container{
        padding: 0;

        h2{
            @include heading1;
            //margin-bottom: 60px;
        }
        .round-button {
            margin: 0 auto;
        }        
       
        .learn-more-anchor {
            position: absolute;
            bottom: 15px;
            left: 0;
            right: 0;
            margin: auto;
            background: url(../images/arrow-down-long.svg) no-repeat 100%;
            background-position: center;
            height: 70px;
            text-indent: -99999px;
            
            @include small-desktop {            
                bottom: 30px;
                transform: scale(0.9);
            }
    
            @include big-desktop {            
                bottom: 60px;
                transform: scale(1);
            }
        }
        #start-safar-container{
            padding-bottom: 0;
        }
    }    
}

#learn-more-what-is-safar, #learn-more-ctas, #learn-more-how-safar-started{
    padding: 120px 9%;
    position: relative;

    @include tablet-portrait {
        padding: 120px 5%;
    }

    @include small-desktop {
        height: 100vh;
        max-width: 1920px;
        padding: 0 12%;
        margin: 0 auto;
        position: relative; 
    }    

    p {
        margin: 0;
        padding-bottom: 20px;
    }
}


#learn-more-what-is-safar, #learn-more-ctas {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center; 
}

#learn-more-how-safar-started{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center; 

    .round-button {
        margin-top: 110px;
    }    
}

#learn-more-what-is-safar{
    #wis-text, #wis-video{
        width: 100%;
           
        @include small-desktop {
            width: 50%; 
        }
    }

    #wis-text {
        text-align: center;
        margin-top: 60px;

        @include small-desktop {
            text-align: left; 
            padding-left: 5%;
            margin-top: auto;
        }
    }

    #wis-video {        
        @include small-desktop {
            padding-right: 5%;
        }
    }

    p {
        display: block;
        width: 100%; 
    }


}

.learn-more-background-overlay {
    background-color: #00000095;
    position: relative;

    .learn-more-anchor {
        filter: invert(92%) sepia(9%) saturate(180%) hue-rotate(356deg) brightness(87%) contrast(89%);
        @include small-desktop {
            //display: none;
        }
    }
}

#learn-more-ctas {    
    p {
        display: block;
        width: 100%;
    }
    #cta-htu{
        background: url('../images/new-design/how-to-use-bg2.svg') no-repeat center center;
        margin-bottom: 50px;   
        
        @include small-desktop {
            margin: 0;
        }
    }
    #cta-ts{        
        /*background: url('../images/new-design/the-steps-bg.svg') no-repeat center center;*/        
    }

    #cta-htu, #cta-ts{
        //border: 4px solid #fff;
        @include border-radius(25px);
        display: flex;
        flex-direction: column;
        justify-content: center;        
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
        background-color: #F6F5EF;

        @include small-desktop {            
            margin: 1.5%;
            width: 45%;
            height: 100%;
            max-height: 420px;
        }
        @include medium-desktop {
            width: 47%;            
            //max-height: 475px;
            max-height: 60vh;
        }
        @include x-big-desktop {
            max-height: 600px;
        }
    }

      
}

/* Base Black pages */

.page-the-steps, .page-how-to-use{
    background-attachment: fixed;
   
    #navbar .wrapper-inner {

        #language-switcher {

            /*li.fa a {
                background: url("/sites/all/themes/safar_rtl/images/new-design/languageicon_EN_White.svg") no-repeat center center;
                background-size: 84% 84%;  
            }  

            li.en a {
                background: url("/sites/all/themes/safar_rtl/images/new-design/languageicon_FA_White.svg") no-repeat center center;
                background-size: 84% 84%;  
            }*/
            
        }
    }

    .main-container {
        padding: 0 10%;
    }

    footer, .main-container {
        background-color: #00000095;
    }
    #navbar {
        position: fixed;
    }
    .footer #block-block-1 p, .footer #block-block-1 p a {
        color: #ffffff;
    }
    #navbar .wrapper-inner .navbar-brand, #navbar .wrapper-inner .navbar-toggle, #navbar .wrapper-inner #user-login-container {        
        filter: brightness(100);
    }
    #church-data-wrapper #church-display-name, #church-data-wrapper #church-web-url {
        color: $white;
    }

    .main-container {
        height: 100%;
        display: block;
        color: #fff;
        background-color: #00000095;

        a, h1, h2{
            color: #fff;
        }

        #how-to-use-container h1, .the-steps-title{
            margin-top: 160px;
            @include small-desktop{
                margin-top: 200px;
            }
        }

        .the-steps-title  p {
            margin-top: 3.5em;
        }
    }
    .round-button {
        margin: 0 auto;        
    }
    .learn-more-anchor-go-back {
        display: none;

        @include small-desktop{
            position: fixed;
            display: block;
            left: 60px;
            top: 50%;
            height: 40px;
            width: 85px;
            background: url(../images/new-design/white-back-arrow.svg) no-repeat 100%;
            background-position: center;                
            text-indent: -99999px;
        }
    }
}

/* The Steps Section */

.page-the-steps {
    
    
    .view-the-steps-legs {

        margin-top: 80px;
        
        &.view-display-id-leg_1 {
            margin-top: 80px;

            @include small-desktop {
                margin-top: 100px;           
            }              

        }

        &.view-display-id-leg_3 {
            margin-bottom: 100px;
        }        

        @include tablet-portrait{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-around;
            margin: 160px 0;
        }

        .item-list ul {  
            margin: 0;
            list-style: none;            

            li {
                margin: 0;
                text-align: left;
            }

            

            @include tablet-portrait{
                display: flex;
                flex-wrap: wrap;
    
                li{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 50%;                  
                }
            }
            @include small-desktop{
                li{
                    padding: 0;                                  
                }
            } 
        }

        .view-header, .view-content{
            width: 100%;

            @include tablet-portrait{
                width: 50%;
            }
        }

        .view-header{
            
            
            @include tablet-portrait{
                display: flex;
                padding: 0 6%;
            }
            @include small-desktop{
                padding: 0 7%;
            }

        }

        .view-content{
            @include tablet-portrait{
                border-left: 1px solid #707070;
                padding-left: 5%;
            }
        }
    }

    .steps-leg-title h2 {
        font-family: $MinionPro-Regular;       
        margin: 0;
        position: relative;
        font-size: 2em;
        line-height: 0.9em;
        margin-bottom: 1em;

        // br {
        //     display: none;
        // }

        @include tablet-portrait{
            margin-bottom: 0em;
            font-size: 4em;
            text-align: left;
            
            // br {
            //     display: block;
            // }

        }


        span {
            font-family: $Gotham-Bold;
            font-size: 0.5em;
            position: absolute;
            top: -1.9em;
            margin-left: -1.7em;
            color: #CBC6BF;

            @include tablet-portrait{
                font-size: 0.3em;
            }
        }

        .leg-colon{
            @include tablet-portrait{
                display: none;
            }            
        }
    }

    .views-field-field-number {
        font-size: 0.8em;
        color: #CBC6BF;
    }
    .views-field-title-field-et {
        font-family: $MinionPro-Regular;
        font-size: 1.8em;
    }
    .views-field-field-subtitle {
        font-size: 0.9em;
        line-height: 1.1em;
        padding-bottom: 15px;
    }
    .views-field-title-field-et, .views-field-field-subtitle {
        padding-left: 30px;
    }

}

/* How to use - Section */

.page-how-to-use {    

    .learn-more-anchor-go-back {
        position: fixed;
        left: 60px;
        top: 50%;
        height: 40px;
        width: 85px;
        background: url(../images/new-design/white-back-arrow.svg) no-repeat 100%;
        background-position: center;                
        text-indent: -99999px;
        display: none;

        @include small-desktop{
            display: block;
        }
    }

    #how-to-use-container{
        max-width: 1200px;
        margin: 0 auto;

        > h1{
            margin-bottom: 45px;
            margin-top: 120px;

            @include small-desktop{
                margin-top: 200px;
                margin-bottom: 90px;
            }
        }

        ul{
            list-style: none;
            margin: 0;
            padding: 0;
             
            li{
                h2{
                    font-family: $MinionPro-Regular;
                    font-weight: 600;
                }
                p{
                    margin-bottom: 45px;
                    @include small-desktop{
                        margin-bottom: 90px;
                    }
                }
                &.last-tip{
                    p{
                        margin-bottom: 160px;
                    }
                }
            }
        }
    }

}

/* Contact us - Section */

.page-contact-us{
    .round-button.black {
        margin: 0 auto;
        margin-top: 90px;
    }
    #contact-us-container{
        p{
            color: $black;
        }
        a {
            font-weight: 600;
            text-decoration: underline;
        }
    }
}



/* New Homepage */

.front {
    .node-page{                  
        position: relative;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        padding: 0 9%;

        @include small-desktop{
            padding: 0;
        }
    }
}







/* RTL Styles */

html[dir="rtl"] {

    .page-the-steps {
    
        .view-the-steps-legs {

            li {
                text-align: right;
            }

            .view-content{
                @include tablet-portrait{
                    border-right: 1px solid #707070;
                    border-left: 0;
                    padding-right: 5%;
                    padding-left: 0;
                }
            }

        }

        .steps-leg-title h2 {
            text-align: right;
            font-family: $MjEgypt;

            span {
                top: -3em;

                @include tablet-portrait{
                    margin-left: 0;
                    margin-right: -1.7em;
                }
            }
        
        }   

        .views-field-title-field-et {
            font-family: $MjEgypt;
        }


        .views-field-title-field-et, .views-field-field-subtitle {
            padding-left: 0;
            padding-right: 30px;
        }
        

        .steps-leg-title h2 {    
            span {
                font-family: $estedad-Medium;
            }    
  
        }
        

    }

    #learn-more-what-is-safar p, #learn-more-ctas p, #learn-more-how-safar-started p {        
        letter-spacing: -0.25px;
    }

    .page-learn-more {    
            
        .main-container{                
            h2{                
                font-family: $MjEgypt;
            }
        }
    }

    #learn-more-what-is-safar{

        #wis-text {

            @include small-desktop {
                text-align: right; 
                padding-left: 0;
                padding-right: 5%;
                margin-top: auto;
            }
        }

    }

/* How to use - Section */

    .page-how-to-use {    
        #how-to-use-container{    
            ul{                     
                li{
                    h2{
                        font-family: $MjEgypt;
                        font-weight: 300;
                    }
                }
            }
        }    
    }

}