.page-my-friends {
  .main-container {
    /*max-width: 650px;*/
    margin: 0 auto;
    padding-bottom: 65px;
  }

  .messages.status {
    display: none;
  }

  #main-content-region {
    text-align: center;
  }

  h2 {
    padding-bottom: .7em;
    margin-bottom: .5em;
    border-bottom: 1px solid $grayt;
  }

  .item-list {
    margin: 0 auto;
    max-width: 300px;
    position: relative;
  }

  .item-list ul,
  .item-list li {
   margin: 0;
   padding: 0;
   text-align: center;
  }

  .pane-safar-steps-system-safar-add-friend h2,
  .block-safar-steps-system-safar-add-friend h2 {
    border: 0;
    margin-bottom: 0;
  }

  .panel-pane, .block-views {
    margin-bottom: 3em;
  }

}

// My friends styles
#safar-add-friend-form {
 max-width: 100%;
 margin: 50px auto;
 margin-top: 0;

 .form-item-name {
   margin: 0 auto;

   @include tablet-landscape {
    max-width: 250px;
   }

   @include small-desktop {
     max-width: 350px;
   }

   label {
     display: none;
   }
 }

 #edit-submit {
   width: auto;
   padding: 0 50px;
   margin-top: 1.2em;
   padding: .7em 2em;
 }
}

.view-display-id-active_friends, .view-display-id-active {

  .views-row-first {
    .views-field {
      padding-top: 15px;
    }
  }

  .views-field-title {
    width: 305px;

    a:last-child {
      text-decoration: underline;
      font-weight: 500;
    }

  }

  .views-field-field-number {
    width: 145px;
    text-align: center;
  }

  .views-field-field-duration-1,
  .views-field-field-duration {
    width: 90px;
    text-align: center;
  }


}

.page-my-friends .my-friends-help-text {
  font-size: 1.5em;
  font-weight: 500;
  color: black;
  margin-bottom: 1em;

  div {
    display: inline-block;

    hr {
      width: 80%;
      height: 1px;
      border: 0;
      background-color: #70707076;
    }
  }

}

.view-display-id-active_friends,
.view-display-id-active {
  .view-empty {
    /*border-top: 1px solid $black;*/
    padding: 18px;

    p:nth-child(1) {
      margin-bottom: 20px;
      @include tablet-landscape {
        margin-bottom: 30px;
      }
    }

    p {
      font-size: 16px;
      line-height: 20px;
      @include tablet-landscape {
        font-size: 25px;
        line-height: 30px
      }
      font-weight: 400;
      color: $black;
      margin: 0;

      font-size: 16px;
    }

  }
}

.pane-my-friends-active-friends {
  .pane-title {
    margin-bottom: 15px;
  }
}

.view-display-id-block_1,
.view-display-id-block_2,
.view-display-id-active_friends {


 ul {
   margin: 0;
   padding: 0;
   list-style: none;

   li.views-row {
     .views-field {
       display: inline-block;
       a {
        color: $black;
       }
     }
     .views-field-title a {
       font-size: 20px;
       margin-right: 0;
       padding: 8px 0;
       display: inline-block;
     }
     .views-field-nothing a {
       font-size: 17px;
       display: none;
     }
     .views-field-title .btn-delete-friend{
       padding-right: 1.3em;
       font-size: 25px;
       position: absolute;
       left: 0;
     }
   }

   li.views-row:hover {
     .views-field-nothing a {
       display: block;
     }
   }
 }


}

@mixin phone-only {

  #safar-add-friend-form #edit-submit {
    margin: 0;
  }

  #safar-add-friend-form .form-item-name {
    margin-bottom: 5px;
  }

  #safar-add-friend-form .form-item-name {
    width: 100%;
  }

}

// RTL
html[dir="rtl"] {

  .view-display-id-block_1,
  .view-display-id-block_2,
  .view-display-id-active_friends {

   ul {
     li.views-row {
       .views-field-title .btn-delete-friend {
         left: unset;
         right: 0;
         font-family: $Gotham-Book;
         font-style: normal;
       }
     }
   }
  }

  #safar-add-friend-form #edit-submit { 
    padding: 0.15em 2em;
  }

}
