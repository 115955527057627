//Base Styles
html {
 /*min-height: 100vh;*/
}

html body.admin-menu {
    margin-top: 0px !important;
}
/********* Walkthrough **********/
#help {
  background-color: #000;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  border-radius: 0px 40px 40px 0px;
  padding: 5px 10px;
  z-index: 9999;
  position: absolute;
  font-size: 16px;
  top: 60px;
  left: 0;
  width: 35px;
  line-height: 25px;
  padding-left: 11px;
  font-family: $Gotham-Book;
  font-weight: 400;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 0.7s ease;
  -webkit-transition: visibility 1s, opacity 0.7s ease;
  cursor: pointer;

  @include tablet-landscape {
    font-size: 20px;
    line-height: 34px;
    top: 13%;
    width: 54px;
    padding-left: 16px;
  }
}

#help.active {
  border: 2px solid #000;
  background-color: transparent;
  color: #000;
  padding: 3px 8px 3px 10px;
  border-left: none;
  text-indent: -9999px;
  line-height: 0;
  @include tablet-landscape {
    padding-left: 16px;
  }
}

#help.active::after {
  content: "X";
  text-indent: 0;
  display: block;
  line-height: 25px;
  @include tablet-landscape {
    line-height: 34px;
  }
}

#help::selection, #help-text-wrapper .field-item::selection {
  background-color: transparent;
}

#help-tip-wrapper{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;

  #help-text-wrapper {
    font-family: $Gotham-Medium;
    font-size: 16px;
    line-height: 23px;    
    letter-spacing: 0;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 7%;
    left: 70px;
    padding-right: 30px;
    width: calc(100vw - 70px);
    @include tablet-portrait {
      font-size: 18px;
      line-height: 26px;
    }
    @include tablet-landscape {
      font-size: 18px;
      top: 5%;
      width: 40vw;
    }
    @include small-desktop {
      top: 10%;
      font-size: 18px;
      line-height: 28px;
      width: 45vw;
      left: 100px;
    }
    @include big-desktop {
      top: 13%;
      font-size: 22px;
      line-height: 30px;
      width: 50vw;
    }
  }
}


/********* END - Walkthrough **********/

body, html {
  font-family: $Gotham-Book;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -.04em;
  color: $gray;
  height: 100%;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;

  /*min-height: 100vh;*/

  align-items: center;
  justify-content: center;

  background: url('../images/body-background-square.jpg') repeat center center;
  font-size: .85em;
  background-size: cover;

  @include tablet-portrait {
  }
  @include small-desktop {
    font-size: 1.1em;
  }
  @include big-desktop {
    font-size: 1.3em;
  }

  > .main-container {
    padding: 5em 0 0 0;
  }

}

/* Cookie Policy height fix and p color */
body.page-node-9 {
  justify-content: unset;

  p {
    color: $black;
  }
}

.page-node-292,
.page-node-291,
.page-node-290,
.page-my-friends {
  height: auto;
  .main-container {
    padding-top: 40px;
  }
}

.page-node-287 {
  display: block;
}

div.messages.status {
  text-align: left;
}

@include tablet-portrait {
  .page-node-292,
  .page-node-291,
  .page-node-290 {
    height: 100%;
    .main-container {
      padding-top: 1em;
    }
  }
}

label, button {
  font-family: $Gotham-Medium;
  font-weight: 500;
  outline: none;
}

label {
  font-family: $Gotham-Medium;
  color: $black;
  margin-bottom: .15em;
}

h1, h2, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
  font-family: $MinionPro-Regular;
  color: $black;
  border: 0;
}

h1, .page-header {
  @include heading1;
}

html[dir="rtl"] {
  h1, .page-header {
      font-family: $MjEgypt;
  }
}

h2 {
  @include heading2;
}

p {
  font-family: $Gotham-Book;
  margin-bottom: 20px;

  @include small-desktop {
  }
}

a {
  font-family: $Gotham-Book;
  text-decoration: none;
  color: $black;
  outline: none;
}

article {
  a {
    text-decoration: underline;
  }
}

.breadcrumb,
.tabs.primary {
  display: none;
}

.element-invisible {
  display: none;
}

.first-sidebar-region {
  padding: 0;

  .region {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0 20px;
    box-shadow: none;
  }

}

body > .main-container {
  flex: 1;
  //align-items: center; //This breaks vertical slide position on Safari
  justify-content: center;
  display: flex;
  padding: 1em 5% 2em 5%;
  line-height: 1.6em;
  width: 100%;
  text-align: center;

  @include tablet-portrait {
    padding-bottom: 3.5em;
  }

  > .row {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
    justify-content: center;
  }

}

.btn-default:hover,
.btn-default:active,
.btn-default:focus,
.btn-default:active:hover,
 .btn-default:active:focus {
    color: $white;
}

/* Round Button Generic Class */
.round-button {
  font-family: $Gotham-Book;
  font-weight: 300;
  text-align: center;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  @include border-radius(50px);
  font-size: .8em;
  display: block;
  width: 160px;
  padding: 0.2em 2em;
  text-decoration: none !important;
  
  @include tablet-portrait {
    min-width: 255px;
  }

  &.black {
    background-color: $black;
    color: $white;
  }

  &.transparent{
    background-color: transparent;
  }
}


.table > thead > tr > td.active,
.table > thead > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  background-color: transparent;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
    background-color: transparent;
}


// Hide temporaly language inline links inside node page
.node-page footer .list-inline {
  .fa, .en {
    display: none;
  }
}

/*** RTL Base ***/

html[dir="rtl"] {

  .i18n-fa{

      body, html {
        font-family: $estedad-Medium;
      }
      label, button  {
        font-family: $estedad-Medium;
      }
      h2, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
        font-family: $MjEgypt;
      }
      p, a {
        font-family: $estedad-Medium;
      }

  }

  .i18n-ps{
    body, html {
      font-family: $notoNaskh;
    }
    label, button  {
      font-family: $notoNaskh;
    }
    h2, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
      font-family: $lateef;
      font-weight: 600;
    }
    p, a {
      font-family: $notoNaskh;
    }
  }

  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: right;
  }
  .nav {
    padding-right: 0;
  }
  .page-header {
    letter-spacing: 0.1px;
  }
}

/* Youtube video responsive */

.responsive-video {
  position: relative;
  padding-bottom: (9/16)*100%; // 16:9 aspect ratio
  padding-top: 25px; // Height of video controls
  height: 0;

  &__embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &--4-3 {
    padding-bottom: (3/4)*100%; // 4:3 aspect ratio
  }
}

html[dir="rtl"] {

  #block-system-user-menu {
    text-align: left;
  }

  #block-locale-language-content {
    .en {
      float: none!important;
    }
  }

  /********* Walkthrough **********/
  #help {
    font-weight: 600;
    border-radius: 40px 0px 0px 40px;
    padding: 5px 10px;
    font-size: 16px;
    right: 0;
    padding-right: 12px;

    @include tablet-landscape {
      font-size: 20px;
      padding-right: 16px;
    }
  }

  #help.active {
    padding: 3px 10px 3px 15px;
    border-right: 0;
    border-left: 2px solid #000!important;
    @include tablet-landscape {
      padding-right: 16px;
    }
  }

  #help-tip-wrapper{
    right: 0;

    #help-text-wrapper {
      font-family: $estedad-Medium;
      font-size: 18px;
      right: 70px;
      padding-left: 30px;
      padding-right: 0;
      @include tablet-portrait {
        font-size: 18px;
        right: 100px;
      }
      @include tablet-landscape {
        font-size: 18px;
      }
      @include small-desktop {
        font-size: 18px;
        left: 100px;
      }
      @include big-desktop {
        font-size: 22px;
      }
    }
  }

  /********* END - Walkthrough **********/
}

// Media Queries

#main-content-region {
  width: 100%;
  padding: 0;
}

@media screen and (max-width: 767px) {

  .first-sidebar-region, #page-header {
    display: none;
  }

  .page-header {
    margin-top: 0;
    margin-bottom: 25px;
  }
}

ul, li {
  margin: 0;
  padding: 0;
  text-indent: 0;
}
