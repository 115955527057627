// Manage Journeys Styles
.view-manage-journeys {

  #views-exposed-form-manage-journeys-manage-journeys {
    margin-bottom: 40px;

    #edit-mail-wrapper, #edit-field-name-value-wrapper, .views-submit-button {
      display: inline-block;
      float: left;
    }

    #edit-mail-wrapper {
      width: 320px;
    }

    #edit-field-name-value-wrapper {
      width: 320px;
    }

    .views-submit-button {
      width: 170px;

      #edit-submit-manage-journeys {
        margin-top: 30px;
      }
    }

    label {
      font-weight: 500;
      font-size: 18px;
    }
  }

  table {

    thead {
      tr {
        th a {
          color: $pink;
          font-size: 16px;
        }
      }
    }

    tbody {

    }

  }

  .views-field-field-date-started-journey,
  .views-field-field-journey-completed,
  .views-field-field-last-step-completed,
  .views-field-uid {
    text-align: center;
  }

}

html[dir="rtl"] {
  .view-manage-journeys {

    .views-field-title {
      padding-left: 0;
      text-align: right;
    }

    #views-exposed-form-manage-journeys-manage-journeys #edit-mail-wrapper,
    #views-exposed-form-manage-journeys-manage-journeys #edit-field-name-value-wrapper,
    #views-exposed-form-manage-journeys-manage-journeys .views-submit-button {
      float: right;
      padding: .5em 0 0 1em;
    }

    .views-submit-button {
      #edit-submit-manage-journeys {
        margin-top: 39px!important;
      }
    }

  }
}
