// Journey Styles

.slide {
  width: 100%;
}

.swiper-button-disabled {
  display: none!important;
}

.swiper-slide {
  height: 100%;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
}

.swiper-container-h .swiper-wrapper {
  height: 100%!important;
}

#ribbon {
  // position: absolute;
  // width: 100%;
  // height: 100%;
  // top: 0;
  // left: 0;
  // overflow: hidden;
}

// #monoribbon {
//   height: 9000px;
//   width: auto;
//   position: absolute;
//   top: -800px;
//   left: 50px;
//   margin-top: 0;
//   margin-left: 40%;
// }

#ribbon {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-left: 0%;
  }

body.node-type-journey {

  /*display: initial;
  min-height: initial;
  flex-direction: initial;
  align-items: initial;
  justify-content: initial;*/

  .fp-scroller {
    height: 100%;
  }

  > .main-container,
  > .main-container > .row {
    flex: initial;
    justify-content: initial;
    display: block;
    padding: 0em;
  }

  #fullpage .section > #footer,
  #footer, /*& >*/
  .step-index-cover #footer,
  .step-details-cover #footer,
  .whatisslide #footer,
  .startslide #footer,
  .landing-section-1 #footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1%;
    z-index: 100;

    @include tablet-portrait {
      bottom: 2%;
    }

    #block-block-1 {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
    }

  }


  #footer {
    /*position: absolute;
    left: 0;
    right: 0;
    bottom: 2%;
    z-index: 100;

    #block-block-1 {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
    }*/
  }

  #navbar {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
//     background-image: url('../images/body-background-fadetransparent.png');
//     background-size: cover;
//     height: 110px;
  }

  h1.page-header,
  a#main-content {
    display: none;
  }

  #fp-nav {
    display: none;
  }

  .fp-alt-nav {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 0;
    left: 11%;
    z-index: 100;
  }

  .fp-alt-nav ul {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
  }

  .fp-alt-nav.fp-show-active a+.fp-tooltip {
    -webkit-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in;
    width: auto;
    opacity: 1;
    position: relative;
  }

  .fp-alt-nav ul li .fp-tooltip.fp-left {
    left: unset;
    display: none;
  }

  .fp-alt-nav ul li a span,
  .fp-alt-nav ul li a.active span,
  .fp-alt-nav ul li:hover a.active span {
    margin: 0;
    background: unset;
    position: unset;
  }

  .fp-alt-nav ul li a {
    font-family: $Gotham-Book;
    font-size: 1.1em;
    color: $disabled-dot;
    text-decoration: none;

    @include small-desktop {
    }
  }

  .fp-alt-nav ul li a:hover {
    text-decoration: none;
  }

  .fp-alt-nav ul li a.active {
    color: $black;
  }

  .fp-alt-nav ul li {
    display: none;

    &.active, &.dot0, &.dot1, &.dot2, &.dot3, &.dot4, &.dot5 {
      display: flex;
      flex: 1 1 auto;
    }

//     &.dot4.above, &.dot5.above {
//       display: none;
//     }

    // @include small-desktop {
    //   &.dot4, &.dot5 {
    //     display: flex;
    //     flex: 1;
    //   }
    // }

    div {
      text-align: right;
      width: 100%;
      min-height: 1.6em; // Matches line height

      .journey-owner {
        display: none;
      }

      a {
        span {
          display: none;
        }
      }

    }



    &.active {
      padding: 0 0 0 0;
      font-weight: 500;
      position: relative;

      a {
        color: $black;
      }

      span {
        display: inline;

        &.journey-owner {
          display: inline-block;
          font-weight: 300;
        }
      }

    }

    // &.dot0 {
    //   @mixin opacity(.8);
    // }

    &.dot1 {
      @include opacity(.8);
    }

    &.dot2 {
      @include opacity(.6);
    }

    &.dot3 {
      @include opacity(.4);
    }

    &.dot4 {
      @include opacity(.2);
    }

    &.dot5 {
      @include opacity(0);
    }

    &.dot1.above {
      @include opacity(.6);
    }

    &.dot2.above {
      @include opacity(.4);
    }

    &.dot3.above {
      @include opacity(.2);
    }

    &.dot4.above {
      @include opacity(0);
    }

    span.journey-owner {
      /*display: inline-flex;*/
      position: fixed;
      margin-left: .5em;
      align-items: center;
      img {
        margin-left: .5em;
        position: fixed;
        margin-top: 0.4em;
        width: 0.8em;
        height: 0.5em;
      }
    }

  }

  .fp-alt-nav li > div {
    height: 30px;
  }

  #Section-Start {
    .paragraphs-column {
        border: none;
    }
  }

  #fullpage {

    /*background-color: rgba(0,0,0,.6);*/

  }
  .step-details {
    .paragraphs-column {
      height: auto;

      @media (max-width: 766px) {
        .footer {
          height: 5%;
          display: flex;
          flex-direction: column;
          justify-items: center;
          align-items: center;
        }
      }

      @include tablet-portrait {
        padding: 0 7% 0 7%;
        height: auto;
      }

      @include tablet-landscape {
        height: 100%;

        &:nth-child(2) {
          overflow: auto;
        }

      }

      a {
        text-decoration: underline;
      }

      p {
        margin: .5em 0;
      }

      ul {
        margin-bottom: .6em;
        li {
          margin: .2em 0;
        }
      }

    }

    #paragraph-audio-and-video {

      > h2 {
        font-size: 1.3em;
        line-height: 1.4em;
      }

      #audio-and-video {
        list-style: none;
        text-align: center;
        padding: 0;
        max-width: 230px;
        margin: 0 auto;
        margin-bottom: 0;

        @include tablet-portrait {
          max-width: none;
        }

        li {
          display: block;
          margin: 0 auto;

          @include tablet-portrait {
            display: inline-block;
          }

          > a {
            font-family: $Gotham-Medium;
            font-size: 1em;
            text-decoration: none;
            letter-spacing: 0;
            display: block;
            border-radius: 100px;
            border: 2px solid #000;
            font-weight: 400;
            padding: 8px 0;
            @include tablet-portrait {
              /*padding: 0.3em 0;*/
            }

            &.video-btn{
              color: $white;
              background-color: $black;
            }

            &.audio-btn {
              color: $black;
              background-color: transparent;
            }
          }

          &.media-button {
            width: 230px;
            @include tablet-portrait {
              width: 42%;
              max-width: 260px;
            }
          }

        }




      }

      #audio-recommended, #av-or-divider {
        font-family: $Gotham-Book;
        color: $black;
        font-size: 0.9em;
        line-height: 1.3em;
        text-align: center;
        letter-spacing: -0.81px;
        font-weight: 300;
        opacity: 0.70;
        margin: 0;
        margin-top: 15px;
        margin-bottom: 15px;

        @include tablet-portrait {
          margin-bottom: 0;
        }

      }

      #av-or-divider {
        font-size: 1.2em;
        @include tablet-portrait {
          font-size: 0.9em;
        }
      }

      @media (max-width: 766px) {

        #av-or-divider {
          display: flex;
          align-items: center;
          width: 100%;
          margin: 0 auto;
          margin-top: 15px;
          margin-bottom: 15px;
          line-height: 20px;
        }

        #av-or-divider:before, #av-or-divider:after {
          height: 2px;
          background: #000000;
          content: "";
          flex: 1;
        }

        #av-or-divider:before {
          margin-right: 1.5em;
        }

        #av-or-divider:after {
          margin-left: 1.5em;
        }

      }

      #paragraph-audio-and-video-fields {
        margin: 30px 0;
        @include tablet-portrait {
          margin: 60px 0;
        }
      }

    }

  }

    .section {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
      color: $black;
      /*opacity: .4;
      transition: opacity linear .3s;*/
      background: url('../images/body-background-square.jpg') no-repeat center center;
      background-size: cover;

      @include small-desktop {
      }

    }

    .section.active {
      opacity: 1;
      transition: opacity linear 0s;
    }

    #step-definition-detail {

      a {
        font-size: 15px;
        text-decoration: underline;
        color: $black;
      }

      .journey-owner {
        h2 {
          font-size: 27px;
        }
      }

      .field-name-field-sub-title {
        font-family: $Gotham-Book;
        font-size: 25px;
        line-height: 25px;
        letter-spacing: -0.75px;
        @include tablet-portrait {
          font-size: 35px;
          line-height: 35px;
          letter-spacing: -1.05px;
        }
      }

    }

    #links-container {
      margin: 30px 0;
    }

    .step-action-links {
      @include button;
      font-size: .8em;
      display: block;
      max-width: 280px;
      margin-bottom: 10px;
      padding: .5em 2em;
      text-decoration: none!important;
      min-width: 255px;

      @include tablet-portrait {
        display: inline-block;
      }
    }

    .fp-slide {

      @include tablet-landscape {
        overflow: none;
      }

      // Required to force flexbox overflow content to be contained at top for scroll
      .paragraphs-column > div {
        margin: auto;
        flex: 1; //required to fill
      }

      .paragraphs-column:first-child {
      }

      .paragraphs-column:last-child, #first-slide-container {
        border-right: 0;
      }

      #first-slide-container {
        justify-content: left;
      }

    }

    .step-def-title {
      padding: 0px 0;
    }

    .step-def-title h2,
    .field-name-field-title h2,
    .field-name-field-title .field-item {
      @include heading1;
    }

    .field-name-field-text-area {
      @include small-desktop {
      }
    }

    .field-name-field-help-text {
      display: none;
    }

    .paragraphs-section {
      width: 100%;
      margin: auto;
      text-align: left;
      padding: 8em 10%;
      height: 100%;
      overflow: auto;
      /*position: absolute;*/

      @include tablet-landscape {
        height: 100%;
        padding: 10% 10%;
        margin: 0;
        display: flex;
        flex-direction: row;
        overflow: inherit;
      }

    }
    /********* Walkthrough **********/
    .section.swiper-slide-active {
      transform-origin: 320% 5%;
      transition: 0.2s linear;
      -webkit-transition: 0.2s linear;
      -moz-transition: 0.2s linear;
      -o-transition: 0.2s linear;
      filter: brightness(100%);

      @media (min-width: 520px) and (max-width: 766px) {
        transform-origin: 250% -5%;
      }

      @include tablet-portrait {
        transform-origin: 200% -5%;
      }

      @include tablet-landscape {
        transform-origin: 100% -5%;
      }
    }

    .section.swiper-slide-active.active {

      transform: rotate(-14deg);

      filter: brightness(65%);
      -webkit-filter: brightness(65%);
      -moz-filter: brightness(65%);
      -o-filter: brightness(65%);
      -ms-filter: brightness(65%);

      box-shadow: inset 0px 5px 2px #0000001f;
      -moz-box-shadow: inset 0px 5px 2px #0000001f;
      -webkit-box-shadow: inset 0px 5px 2px #0000001f;
    }

    .fp-slidesContainer .step-details .paragraphs-section {
      width: 100%;
      margin: auto;
      text-align: left;
      padding: 8em 10%;
      height: auto;
      min-height: 95%;

      @include tablet-landscape {

        height: 100%;
        padding: 10% 10%;
        margin: 0;
        display: flex;
        flex-direction: row;
      }

    }
    /********* END - Walkthrough **********/

    // Cover slide styles
    .paragraphs-column {

      width: auto;
      height: 100%;
      display: flex;
      flex-grow: 1;
      flex-basis: 0;
      align-items: center;
      justify-content: center;
      text-align: left;

      @include tablet-landscape {
        border-right: 2px solid $grayt;
      }

      // Column basic style override
      ol {
        margin: 0;
        padding: 0;
      }

      ul, ol {
        padding-inline-start: 1.1em;
      }

      li {
        padding-inline-start: .5em;
      }

    }

    //We need to use his class in a separate element .fp-bg
    .step-index-cover {
      //background: url('../images/ribbon-mono.svg');
      //background-size: auto 800%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .step-details-cover {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 65% 45%;

      @include tablet-landscape {
        background-size: 300% 300%;
      }

      .paragraphs-column {
          justify-content: left;
      }

    }

    // Brilliant! Intro slide
    #Section-Intro {
      background-image: url('../images/ribbon-ls-1.svg');
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .slide {
      width: 100%;
    }

    .swiper-slide {
      height: 100%;
    }

    .fp-slidesContainer .swiper-slide {
      /*overflow: auto;*/
      flex-direction: column;

      @include tablet-landscape {
        overflow: inherit;
      }
    }

    // Steps  Shapes: 1:8, 2:9, 3:10, 4:11, 5:12, 6:13, 7:14 (number relates to bg svg image, not data id)
    .swiper-slide[data-order="1"], .swiper-slide[data-order="15"], .swiper-slide[data-order="29"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-2.svg');
      background-position: 70% 45%;
    }}

    .swiper-slide[data-order="2"], .swiper-slide[data-order="16"], .swiper-slide[data-order="30"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-3.svg');
    }}

    .swiper-slide[data-order="3"], .swiper-slide[data-order="17"], .swiper-slide[data-order="31"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-4.svg');
      background-position: 65% 45%;
    }}

    .swiper-slide[data-order="4"], .swiper-slide[data-order="18"], .swiper-slide[data-order="32"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-5.svg');
      background-position: 90% 45%;
    }}

    .swiper-slide[data-order="5"], .swiper-slide[data-order="19"], .swiper-slide[data-order="33"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-6.svg');
      background-position: 82% 45%
    }}

    .swiper-slide[data-order="6"], .swiper-slide[data-order="20"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-7.svg');
      background-position: 45% 45%;
    }}

    .swiper-slide[data-order="7"], .swiper-slide[data-order="21"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-8.svg');
    }}

    .swiper-slide[data-order="8"], .swiper-slide[data-order="22"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-9.svg');
      background-position: 70% 45%;
    }}

    .swiper-slide[data-order="9"], .swiper-slide[data-order="23"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-10.svg');
    }}

    .swiper-slide[data-order="10"], .swiper-slide[data-order="24"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-11.svg');
    }}

    .swiper-slide[data-order="11"], .swiper-slide[data-order="25"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-12.svg');
      background-position: 90% 45%;
    }}

    .swiper-slide[data-order="12"], .swiper-slide[data-order="26"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-13.svg');
      background-position: 82% 45%
    }}

    .swiper-slide[data-order="13"], .swiper-slide[data-order="27"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-14.svg');
      background-position: 45% 45%;
    }}

    .swiper-slide[data-order="14"], .swiper-slide[data-order="28"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-1.svg');
    }}


    // .swiper-slide[data-order="1"], .swiper-slide[data-order="6"], .swiper-slide[data-order="11"], .swiper-slide[data-order="16"], .swiper-slide[data-order="21"], .swiper-slide[data-order="26"] {
    //   #monoribbon { top: -800px; .color { fill: #74C1F8; } .color-shadow { fill: #6EB1E2; }  }
    // }

    // .swiper-slide[data-order="2"], .swiper-slide[data-order="7"], .swiper-slide[data-order="12"], .swiper-slide[data-order="17"], .swiper-slide[data-order="22"], .swiper-slide[data-order="27"] {
    //   #monoribbon { top: -1400px; left: -90px; .color { fill: #F4CA00; } .color-shadow { fill: #E2BD10; }  }
    // }

    // .swiper-slide[data-order="3"], .swiper-slide[data-order="8"], .swiper-slide[data-order="13"], .swiper-slide[data-order="18"], .swiper-slide[data-order="23"], .swiper-slide[data-order="28"] {
    //   #monoribbon { top: -2000px; left: -200px; .color { fill: #C5F22F; } .color-shadow { fill: #ACD035; }  }
    // }

    // .swiper-slide[data-order="4"], .swiper-slide[data-order="9"], .swiper-slide[data-order="14"], .swiper-slide[data-order="19"], .swiper-slide[data-order="24"], .swiper-slide[data-order="29"] {
    //   #monoribbon { top: -2200px; left: -160px; .color { fill: #FE3C36; } .color-shadow { fill: #DE2924; }  }
    // }

    // .swiper-slide[data-order="5"], .swiper-slide[data-order="10"], .swiper-slide[data-order="15"], .swiper-slide[data-order="20"], .swiper-slide[data-order="25"], .swiper-slide[data-order="30"] {
    //   #monoribbon { top: -3300px; left: -90px; .color { fill: #4BBBDD; } .color-shadow { fill: #4A96AD; }  }
    // }



    // #Section66 .step-index-cover {
    //   background-position: right -300px top -1100px;
    // }

    // #Section67 .step-index-cover {
    //   background-position: right -300px top -1500px;
    // }

    // Cover slides layout
    .step-details-cover,
    .step-index-cover {

      .paragraphs-section {
        @include tablet-landscape {
          padding: 10% 15%;
        }
      }

      .paragraphs-column {
        border-right: none;

        @include tablet-landscape {
          padding: 0 50% 0 0;
          text-align: left;
        }

        a.btn-arrow-start,
        a.btn-arrow-start:hover {
          display: block;
          background: url(../images/arrow-right-long.svg) no-repeat 85% bottom;
          color: $black;
          text-decoration: none;
          margin-top: .8em;
          padding-top: 2em;
          padding-bottom: 2em;
          background-size: 17.5% auto;


          @include tablet-portrait {
            padding-top: .5em;
            background-position: 70% bottom;
            background-size: 10% auto;
          }

          @include tablet-landscape {
            background-size: 17% auto;
          }


        }

        h2 {
          margin-bottom: 0 !important;
        }

        .field-subtitle p,
        .field-name-field-sub-title {

          font-family: $Gotham-Book;
          font-size: 1.3em;
          letter-spacing: -1.2px;
          line-height: 1.65em;
          margin: 0;

          @include small-desktop {
            font-size: 1.5em;
            line-height: 35px;
            letter-spacing: -1.4px;
          }

        }

      }


    }

    /* END - first-slide-container */

    /* START - Horizontal DOTS */

    .swiper-pagination-bullets {
      text-align: center;
      bottom: 4%;
      z-index: 400;

      @include tablet-portrait {
        bottom: 6.5%;
      }

      .swiper-pagination-bullet {
        margin: 7px;
        opacity: .5;
        height: 6px;
        width: 6px;
        vertical-align: middle;

       &.swiper-pagination-bullet-active {
         opacity: 1;
         height: 11px;
         width: 11px;
         background: $black!important;
       }
      }

    }

    /* END - Vertical DOTS */

    /* ARROWS */
    .fp-controlArrow.fp-prev, .swiper-button-prev,
    .fp-controlArrow.fp-next, .swiper-button-next {
      border: unset;
      width: 85px;
      height: 60px;
      margin: 0;
      outline: none;

      @include tablet-landscape {
        /*display: block;*/
      }

    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
      top: 45%;
      @include x-big-desktop {
        top: 47%;
      }
    }

    .swiper-button-prev {
      background: url('../images/arrow-left.svg') no-repeat center left;
      left: 4.5%;
      background-size: 80px 15px;
      /*visibility: hidden;*/
      @include tablet-landscape {
        /*visibility: hidden;*/
      }
    }
    .swiper-button-next {
      background: url('../images/arrow-right.svg') no-repeat center right;
      right: 4.5%;
      background-size: 80px 15px;
      /*visibility: hidden;*/
      @include tablet-landscape {
        /*visibility: hidden;*/
      }
    } /* END - ARROWS*/



/************** Step Hide and Display complete icons ****************/

.journey-owner.step_status_completed {
    padding-right: 30px;
    background: url(../images/completed.svg) no-repeat right 1px;
    background-size: 13px 12px;
    @include small-desktop {
      background: url(../images/completed.svg) no-repeat right 5px;
      background-size: 18px 12px;
    }

}

.step_status_completed {
  .journey-owner span {
    padding-right: 30px;
    background: url(../images/completed.svg) no-repeat right 1px;
    background-size: 13px 12px;
    @include small-desktop {
      background: url(../images/completed.svg) no-repeat right 5px;
      background-size: 18px 12px;
    }
  }
}

.step_status_incomplete .journey-owner.step_status_completed {
  background: none;
}

.step_status_complete .journey-owner.step-not-completed,
.step_status_complete .fp-alt-nav ul li.active span.journey-owner.step_not_completed span {
    padding-right: 30px;
    background: url(../images/completed.svg) no-repeat right 1px;
    background-size: 13px 12px;
    @include small-desktop {
      background: url(../images/completed.svg) no-repeat right 5px;
      background-size: 18px 12px;
    }
}

.step_status_incomplete .step_status_completed {
  .journey-owner span {
    background: none;
  }
}

.step_status_incomplete .journey-owner.step_status_completed {
   span {
    background: none;
  }
}

.step_status_complete .journey-owner {
  span {
    padding-right: 30px;
    background: url(../images/completed.svg) no-repeat right 1px;
    background-size: 13px 12px;
    @include small-desktop {
      background: url(../images/completed.svg) no-repeat right 5px;
      background-size: 18px 12px;
    }
  }
}

.step_status_complete .journey-owner.step_not_completed,
.step_status_complete .journey-owner.step_status_completed {
  background: none;
}

.journey-owner step_status_completed

/*************** Step Status - Buttons ***************/

.button-step-status, .step-action-links.return-steps {
  padding: .3em 2em;
  min-width: 255px;
}

.step-action-links.return-steps {
  border: 2px solid #000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.step-action-links.return-steps:hover {
  border: 2px solid #707070;
}
.button-step-status.completed {
  background: url(../images/completed-white.svg) no-repeat center left 15px;
  background-size: 13px 18px;
  @include small-desktop {
    background-size: 20px 18px;
  }
  background-color: #36ce7c;
  border: 2px solid #36ce7c;
}

/*************** Step Status - Message ****************/

#step-done{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  max-height: 0;
  min-height: 0;
}

#step-incomplete {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
  max-height: none;
  min-height: 175px;
  @include tablet-landscape {
    min-height: 200px;
  }
}

.step-finish #links-container a {
  display: block;
  padding: .3em 2em;
}

.step_status_incomplete {
  .button-step-status {
    background-color: #000;
  }
  .button-step-status.incomplete {
    background-color: transparent;
    border: 2px solid #000;
    color: #000;
  }
  .button-step-status.completed {
    background: none;
    background-color: transparent;
    border: 2px solid #000;
    color: #000;
  }
  #step-done {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    max-height: 0;
    min-height: 0;
  }
  #step-incomplete {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
    max-height: none;
    min-height: 175px;
    @include tablet-landscape {
      min-height: 200px;
    }
  }

}

.step_status_complete {
  .button-step-status {
    background: url(../images/completed-white.svg) no-repeat center left 15px;
    background-color: #36ce7c;
    background-size: 13px 18px;
    @include small-desktop {
      background-size: 20px 18px;
    }
  }
  .button-step-status.incomplete, .button-step-status.completed {
    border: 2px solid #36ce7c;
  }

  #step-done {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
    max-height: none;
    min-height: 175px;
    @include tablet-landscape {
      min-height: 200px;
    }
  }
  #step-incomplete {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    max-height: 0;
    min-height: 0;
  }
  .step-action-links.return-steps {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
  }
}
.step_status_completed {
  #step-done {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
    max-height: none;
    min-height: 175px;
    @include tablet-landscape {
      min-height: 200px;
    }
  }
  #step-incomplete {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    max-height: 0;
    min-height: 0;
  }
  .step-action-links.return-steps {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
  }
}





/*****************************************/

}


// Just LTR
html[dir="ltr"] {
  body.node-type-journey {
    .step-details {
      .paragraphs-column {
         line-height: 1.35em;
        a {
          text-decoration: underline;
        }
        p {
          margin: .5em 0;
        }
        ul {
          margin-bottom: .6em;
          li {
            margin: .2em 0;
          }
        }
      }

    }
  }

}


// RTL
html[dir="rtl"] {

  body.node-type-journey {

    .step-action-links {
      font-family: $estedad-Medium;
      padding: .3em 2em;
      letter-spacing: 0.1px;
    }

    .fp-alt-nav {
      right: 11%;
      left: unset;
    }

    .step-details {
    .field-name-field-title .field-item {
        letter-spacing: 0.1px;
      }

      p {
        margin: 10px 0;
        line-height: 1.55em;
      }

      li {
        line-height: 1.55em;
      }
    }

    #Section-Intro {
      background-image: url("../images/ribbon-ls-1_rtl.svg");
    }

    .swiper-slide[data-order="1"], .swiper-slide[data-order="15"], .swiper-slide[data-order="29"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-2_rtl.svg');
      background-position: 30% 45%;
    }}

    .swiper-slide[data-order="2"], .swiper-slide[data-order="16"], .swiper-slide[data-order="30"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-3_rtl.svg');
      background-position: 33% 50%;
    }}

    .swiper-slide[data-order="3"], .swiper-slide[data-order="17"], .swiper-slide[data-order="31"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-4_rtl.svg');
      background-position: 35% 45%;
    }}

    .swiper-slide[data-order="4"], .swiper-slide[data-order="18"], .swiper-slide[data-order="32"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-5_rtl.svg');
      background-position: 10% 45%;
    }}

    .swiper-slide[data-order="5"], .swiper-slide[data-order="19"], .swiper-slide[data-order="33"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-6_rtl.svg');
      background-position: 18% 45%
    }}

    .swiper-slide[data-order="6"], .swiper-slide[data-order="20"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-7_rtl.svg');
      background-position: 55% 45%;
    }}

    .swiper-slide[data-order="7"], .swiper-slide[data-order="21"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-8_rtl.svg');
      background-position: 35% 43%;
    }}

    .swiper-slide[data-order="8"], .swiper-slide[data-order="22"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-9_rtl.svg');
      background-position: 30% 45%;
    }}

    .swiper-slide[data-order="9"], .swiper-slide[data-order="23"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-10_rtl.svg');
      background-position: 35% 45%;
    }}

    .swiper-slide[data-order="10"], .swiper-slide[data-order="24"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-11_rtl.svg');
      background-position: 35% 45%;
    }}

    .swiper-slide[data-order="11"], .swiper-slide[data-order="25"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-12_rtl.svg');
      background-position: 10% 45%;
    }}

    .swiper-slide[data-order="12"], .swiper-slide[data-order="26"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-13_rtl.svg');
      background-position: 18% 45%
    }}

    .swiper-slide[data-order="13"], .swiper-slide[data-order="27"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-14_rtl.svg');
      background-position: 55% 45%;
    }}

    .swiper-slide[data-order="14"], .swiper-slide[data-order="28"] { .step-index-cover, .step-details-cover {
      background-image: url('../images/ribbon-ls-1_rtl.svg');
      background-position: 35% 45%;
    }}


    #audio-recommended, #av-or-divider, .step-details #paragraph-audio-and-video #audio-and-video li > a {
      font-family: $estedad-Medium!important;
      font-weight: 300;
      font-size: 0.9em;
    }

    @media (max-width: 766px) {

      #av-or-divider:before {
        margin-left: 1.5em;
      }

      #av-or-divider:after {
        margin-right: 1.5em;
      }

    }

    .step-details #paragraph-audio-and-video #audio-and-video li {
      line-height: normal;
    }

  }

  .fp-alt-nav ul li {
    div {
      text-align: left;
    }
    a {
      font-family: $MjEgypt;
      font-size: 1.5em;
      font-weight: 600;
    }
    .step-word-prefix {
      font-family: $estedad-Bold;
      font-size: 0.7em;
    }
  }

  .swiper-button-prev {
    background: url('../images/arrow-right.svg') no-repeat center left;
    right: 4.5%;
    left: unset;
    background-size: 80px 15px;
  }
  .swiper-button-next {
    background: url('../images/arrow-left.svg') no-repeat center right;
    left: 4.5%;
    right: unset;
    background-size: 80px 15px;
  }

  .fp-slide {
    #first-slide-container {
      justify-content: right;
    }

    .paragraphs-column:last-child, #first-slide-container {
      border-left: 0;
    }
  }

  .paragraphs-column {

    text-align: right;

    @include tablet-landscape {
      border-left: 2px solid $grayt;
      border-right: 0;
    }
  }

  .step-details-cover {
    .paragraphs-column {
        justify-content: right;
    }
  }

  .step-details-cover .paragraphs-column,
  .step-index-cover .paragraphs-column {

    a.btn-arrow-start,
    a.btn-arrow-start:hover {
      background: url(../images/arrow-left-long.svg) no-repeat 15% bottom;
      background-size: 17.5% auto;
      padding-bottom: 2em;
      margin-top: 1.5em;
      text-align: right;

      @include tablet-portrait {
        background-size: 10% auto;
      }

      @include tablet-landscape {
        background-size: 17% auto;
      }
    }

    @include tablet-landscape {
      padding: 0 0 0 50%;
      text-align: right;
      border: none;
    }

    .field-subtitle p,
    .field-name-field-sub-title {

      font-family: $estedad-Medium;
      letter-spacing: auto;

      @include small-desktop {
        letter-spacing: 0;
      }

    }

    h2 {
      letter-spacing: 0.1px;
    }

  }

  /************** Step Hide and Display complete icons ****************/

  .journey-owner.step_status_completed {
    padding-right: 0;
    padding-left: 30px;
    background: url(../images/completed.svg) no-repeat center left;
    background-size: 13px 12px;
    @include small-desktop {
      background: url(../images/completed.svg) no-repeat center left;
      background-size: 18px 12px;
    }
  }

  .step_status_completed {
    .journey-owner span {
      padding-right: 0;
      padding-left: 30px;
      background: url(../images/completed.svg) no-repeat center left;
      background-size: 13px 12px;
      @include small-desktop {
        background: url(../images/completed.svg) no-repeat center left;
        background-size: 18px 12px;
      }
    }
  }

  .step_status_incomplete .journey-owner.step_status_completed {
    background: none;
  }

  .step_status_complete .journey-owner.step-not-completed,
  .step_status_complete .fp-alt-nav ul li.active span.journey-owner.step_not_completed span {
    padding-right: 0;
    padding-left: 30px;
    background: url(../images/completed.svg) no-repeat center left;
    background-size: 13px 12px;
    @include small-desktop {
      background: url(../images/completed.svg) no-repeat center left;
      background-size: 18px 12px;
    }
  }

  .step_status_incomplete .step_status_completed {
    .journey-owner span {
      background: none;
    }
  }

  .step_status_incomplete .journey-owner.step_status_completed {
     span {
      background: none;
    }
  }

  .step_status_complete .journey-owner {
    span {
      padding-right: 0;
      padding-left: 30px;
      background: url(../images/completed.svg) no-repeat center left;
      background-size: 13px 12px;
      @include small-desktop {
        background: url(../images/completed.svg) no-repeat center left;
        background-size: 18px 12px;
      }
    }
  }

  .fp-alt-nav .journey-owner.step_status_completed {
    background: url(../images/completed.svg) no-repeat center left;
    background-size: 13px 12px;
    @include small-desktop {
      background-size: 18px 12px;
    }
  }

  .step_status_complete .journey-owner.step_not_completed,
  .step_status_complete .journey-owner.step_status_completed,
  .step_status_incomplete .fp-alt-nav .journey-owner.step_status_completed  {
    background: none;
  }

  .button-step-status.completed {
    background: url(../images/completed-white.svg) no-repeat center right 15px;
    background-color: #36ce7c;
    background-size: 13px 18px;
    @include small-desktop {
      background-size: 20px 18px;
    }
  }

  .step_status_complete {
    .button-step-status {
      background: url(../images/completed-white.svg) no-repeat center right 15px;
      background-color: #36ce7c;
      background-size: 13px 18px;
      @include small-desktop {
        background-size: 20px 18px;
      }
    }
  }


  .swiper-slideContainer {
    float: right;
    position: relative;
  }
  .fp-slide {
    float: right;
  }

  .fp-alt-nav ul li {

    div {
      min-height: 1.6em; // Matches line height
    }

    a {
     /*line-height: 0px;*/
     span.journey-owner {
       font-family: $estedad-Medium;
       font-size: 0.8em;
       line-height: 2.2em;
       position: fixed;
       margin-right: .5em;
       img {
         margin-right: .5em;
         position: fixed;
         margin-top: 0.7em;
         width: 0.8em;
         height: 0.5em;
       }
     }
   }

  }



  /********* Walkthrough **********/
  .section.swiper-slide-active {
    transform-origin: -200% 0;

    @media (min-width: 520px) and (max-width: 766px) {
      transform-origin: -170% 0;
    }

    @include tablet-portrait {
      transform-origin: -70% 0;
    }

    @include tablet-landscape {
      transform-origin: 0 0;
    }
  }

  .section.swiper-slide-active.active {
    transform: rotate(14deg);
  }

  .fp-slidesContainer .step-details .paragraphs-section {
    text-align: right;
  }
  /********* END - Walkthrough **********/



}
