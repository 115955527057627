// Header bar styles

/* rtl:begin:ignore */
#navbar {
  padding: 5% 9% 0;
  align-self: flex-start;
  width: 100%;
  margin: 0;
  top: 0;

  @include tablet-portrait {
    padding: 3% 5% 0;
  }
  @include small-desktop {
    padding: 3% 6% 0;
  }

  .wrapper-inner {
    text-align: right;
    direction: ltr;

    @include tablet-portrait {
    }

    @include small-desktop {

    }

    .navbar-brand {
      font-size: 30px;
      color: $darkgray;
      display: block;
      text-indent: 140%;
      overflow: hidden;
      background: url('../images/safar_logo.svg') no-repeat center center;
      /*background-size: auto 50%;*/
      background-repeat: no-repeat;
      height: 55px * .7;
      width: 100px * .7;
      padding: 0;
      float: left;
      filter: contrast(100);

      @include tablet-portrait {
        height: 55px * .8;
        width: 100px * .8;
      }
      @include small-desktop {
        height: 55px * .8;
        width: 100px * .8;
      }
      @include big-desktop {
        height: 55px;
        width: 100px;
      }
    }

    .navbar-toggle {
      display: inline-block;
      height: 55px * .7;
      width: 55px * .7;
      text-indent: 100%;
      background: url('../images/menu-icon.svg') no-repeat center center;
      background-size: 50% 50%;
      background-repeat: no-repeat;
      padding: 0;
      margin: 0;
      border: unset;
      border-radius: unset;
      vertical-align: top;
      outline: none;
      position: relative;
      filter: contrast(100);
    }

    #user-login-container {
      display: inline-block;
      height: 55px * .7;
      width: 55px * .7;
      background: url('../images/login-icon.svg') no-repeat center center;
      background-size: 50% 50%;
      background-repeat: no-repeat;
      vertical-align: top;
      filter: contrast(100);
      
      a {
        display: inline-block;
        white-space: nowrap;
        text-indent: 100%;
        overflow: hidden;
        width: 100%;
        height: 100%;
      }

    }

    .navbar-toggle,
    #user-login-container,
    .langswitcher-toggle {
      @include tablet-portrait {
        height: 55px * .8;
        width: 55px  * .8;
        margin-left: 5px;
      }
      @include small-desktop {
        height: 55px * .8;
        width: 55px * .8;
      }
      @include big-desktop {
        height: 55px;
        width: 55px;
      }
    }
  }

}

/* Church Styles */

#church-data-wrapper{
  float: left;
  padding-top: 0;
  text-align: left; 

  #church-display-name, #church-web-url {
    color: $black;
    display: block;
    letter-spacing: 0;
  }

  #church-display-name {
    font-size: 1.05em;
    line-height: 1em;
    font-weight: 500;
  }
  
  #church-web-url {
    font-size: 1.05em;    
  }

  @include tablet-portrait {
    #church-web-url {
      font-size: 0.96em;
    }
  }

  #church-display-name {    
    margin: 0;        
  }

  #church-web-url {
    font-family: $Gotham-Book;
    text-decoration: underline;        
  }

  .english-display-name {
    font-family: $Gotham-Medium;
  }
  
  .persian-display-name {
    font-family: $estedad-Medium;
  } 

}
  
/* END - Church Styles */

.not-logged-in {
  #navbar {
    .navbar-toggle {
      //float: right;
    }
  }
}

/* rtl:end:ignore */