// Invite Helper Styles
#safar-invite-helper-form, #safar-email-login-form {

  #edit-name, #edit-email, #edit-friend-name {
    width: 100%;
    max-width: 256px;
    margin: 0 auto;

    @include tablet-portrait {
      max-width: 408px;
    }
    @include small-desktop {
      max-width: 408px;
    }
  }

  .form-item-name {
    margin-top: 35px;
    margin-bottom: 20px;

    @include tablet-portrait {
      margin-top: 35px;
      margin-bottom: 20px;
    }
    @include small-desktop {
      margin-top: 60px;
      margin-bottom: 30px;
    }

    .help-block {
      display: none;
    }
  }

  .form-item-email {
    margin-bottom: 20px;

    @include tablet-portrait {
      margin-bottom: 20px;
    }
    @include small-desktop {
      margin-bottom: 30px;
    }

    .help-block {
      font-size: 18px;
      font-weight: 300;
      color: $midgray;
      margin-top: 15px;
    }
  }

}

#safar-email-login-form {
  .form-item-email {
    margin-top: 45px;
    margin-bottom: 65px;

    @include tablet-portrait {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    @include small-desktop {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    #edit-email {
      margin-bottom: 40px;
    }
  }
}



.page-invite-helper, .page-user-login {

  article {
    header {
      display: none;
    }
  }

  .main-container {
    text-align: center;

    @include tablet-portrait {
      padding: 0 30%;
    }
  }



  .field-name-body, .pane-custom.pane-1 {
    p {
      color: $black;
      margin-bottom:0;
      @include small-desktop {
        margin-bottom: initial;
      }
      a {
        color: $black;
        text-decoration: underline;
        font-weight: 500;
        @include small-desktop {
        }
      }
    }
  }

}

.page-user-login, .page-invite-helper {
  .page-header {
    margin-bottom: 10px;
    @include small-desktop {
    }
  }
}

#no-login-reg {
  p {
    color: $black;
    a {
      text-decoration: underline;
      font-weight: 500;
    }
  }
}

html[dir="rtl"] {

  #safar-email-login-form {
    .description, label, #no-login-reg p {
      font-family: $estedad-Medium;
      color: $black;
    }
  }

  #safar-invite-helper-form, #safar-email-login-form {
    label, p, a , input, #edit-submit {
      font-family: $estedad-Medium;
    }
    #edit-email {
      direction: ltr;
    }
  }

  #no-login-reg {
    p {
      a {
        font-weight: 600;
      }
    }
  }

  .field-name-body, .pane-custom.pane-1 {
    p {
      a {
        font-weight: 600;
      }
    }
  }

  .page-invite-helper, .page-user-login {

    article {
      header {
        display: none;
      }
    }

    .main-container {
      text-align: center;

      @include tablet-portrait {
        padding: 0 30%;
      }
    }

  }

}
