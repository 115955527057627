
// Hosted fonts (MinionPro, Gotham)
@import url("https://cloud.typography.com/7029016/6607192/css/fonts.css");
@import url("https://use.typekit.net/svp6egb.css");

// Mj_Egypt Font
@font-face {
  font-family: 'Mj_Egypt';
  src:  url('../fonts/mj_egypt/Mj_Egypt.eot?#iefix') format('embedded-opentype'),
        url('../fonts/mj_egypt/Mj_Egypt.woff') format('woff'),
        url('../fonts/mj_egypt/Mj_Egypt.ttf')  format('truetype'),
        url('../fonts/mj_egypt/Mj_Egypt.svg#Mj_Egypt') format('svg');
  font-weight: normal;
  font-style: normal;
}
// Estedad Fonts
@font-face {
  font-family: 'Estedad-Thin';
  src:  url('../fonts/estedad/Estedad-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/estedad/Estedad-Thin.woff') format('woff'),
        url('../fonts/estedad/Estedad-Thin.ttf')  format('truetype'),
        url('../fonts/estedad/Estedad-Thin.svg#Estedad-Thin') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Estedad-Light';
  src:  url('../fonts/estedad/Estedad-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/estedad/Estedad-Light.woff') format('woff'),
        url('../fonts/estedad/Estedad-Light.ttf')  format('truetype'),
        url('../fonts/estedad/Estedad-Light.svg#Estedad-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Estedad-Medium';
  src:  url('../fonts/estedad/Estedad-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/estedad/Estedad-Medium.woff') format('woff'),
        url('../fonts/estedad/Estedad-Medium.ttf')  format('truetype'),
        url('../fonts/estedad/Estedad-Medium.svg#Estedad-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Estedad-Bold';
  src:  url('../fonts/estedad/Estedad-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/estedad/Estedad-Bold.woff') format('woff'),
        url('../fonts/estedad/Estedad-Bold.ttf')  format('truetype'),
        url('../fonts/estedad/Estedad-Bold.svg#Estedad-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Estedad-Black';
  src:  url('../fonts/estedad/Estedad-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/estedad/Estedad-Black.woff') format('woff'),
        url('../fonts/estedad/Estedad-Black.ttf')  format('truetype'),
        url('../fonts/estedad/Estedad-Black.svg#Estedad-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}
// Harabara Font
@font-face {
  font-family: 'HarabaraMaisBold-HarabaraMaisBold';
  src: url('../fonts/harabara/HarabaraMaisBold-HarabaraMaisBold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/harabara/HarabaraMaisBold-HarabaraMaisBold.otf')  format('opentype'),
	     url('../fonts/harabara/HarabaraMaisBold-HarabaraMaisBold.woff') format('woff'),
       url('../fonts/harabara/HarabaraMaisBold-HarabaraMaisBold.ttf')  format('truetype'),
       url('../fonts/harabara/HarabaraMaisBold-HarabaraMaisBold.svg#HarabaraMaisBold-HarabaraMaisBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Lateef
@font-face {
  font-family: 'lateef';
  src: url('../fonts/lateef/Lateef-Regular.eot'),
       url('../fonts/lateef/Lateef-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/lateef/Lateef-Regular.woff2') format('woff2'),
       url('../fonts/lateef/Lateef-Regular.woff') format('woff'),
       url('../fonts/lateef/Lateef-Regular.ttf')  format('truetype'),
       url('../fonts/lateef/Lateef-Regular.svg#Lateef') format('svg');
       font-weight: normal;
       font-style: normal;
}

// noto_naskh
@font-face {
  font-family: 'noto_naskh';
  src: url('../fonts/noto_naskh/NotoNaskhArabic.eot'),
       url('../fonts/noto_naskh/NotoNaskhArabic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/noto_naskh/NotoNaskhArabic.woff2') format('woff2'),
       url('../fonts/noto_naskh/NotoNaskhArabic.woff') format('woff'),
       url('../fonts/noto_naskh/NotoNaskhArabic.ttf')  format('truetype'),
       url('../fonts/noto_naskh/NotoNaskhArabic.svg#Noto Naskh Arabic') format('svg');
  font-weight: normal;
  font-style: normal;
} 

// Neutral Fonts
$Gotham-Light: "Gotham SSm A", "Gotham SSm B", Tahoma, Verdana, sans-serif;
$Gotham-Book: "Gotham SSm A", "Gotham SSm B", Tahoma, Verdana, sans-serif;
$Gotham-Medium: "Gotham SSm A", "Gotham SSm B", Tahoma, Verdana, sans-serif;
$Gotham-Bold: "Gotham SSm A", "Gotham SSm B", Tahoma, Verdana, sans-serif;
// English Fonts
$MinionPro-Regular: 'minion-pro', Tahoma, Verdana, sans-serif;
$MinionPro-Bold: 'minion-pro', Tahoma, Verdana, sans-serif;
$MinionPro-It: 'minion-pro', Tahoma, Verdana, sans-serif;
$MinionPro-MediumIt: 'minion-pro', Tahoma, Verdana, sans-serif;
$Harabara: 'HarabaraMaisBold-HarabaraMaisBold', Tahoma, Verdana, sans-serif;
// Farsi Fonts
$MjEgypt: 'Mj_Egypt', "DejaVu Sans", Tahoma, Verdana, sans-serif;
$estedad-Thin: 'Estedad-Thin', "DejaVu Sans", Tahoma, Verdana, sans-serif;
$estedad-Light: 'Estedad-Light', "DejaVu Sans", Tahoma, Verdana, sans-serif;
$estedad-Medium: 'Estedad-Medium', "DejaVu Sans", Tahoma, Verdana, sans-serif;
$estedad-Bold: 'Estedad-Bold', "DejaVu Sans", Tahoma, Verdana, sans-serif;
$estedad-Black: 'Estedad-Black', "DejaVu Sans", Tahoma, Verdana, sans-serif;
// Pashto Fonts
$lateef: 'lateef', "DejaVu Sans", Tahoma, Verdana, sans-serif;
$notoNaskh: 'noto_naskh', "DejaVu Sans", Tahoma, Verdana, sans-serif;

/*
@font-face {
  font-family: 'Nassim';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/bbc-nassim-regular.eot');
  src: url('../fonts/bbc-nassim-regular.woff') format('woff'),
       url('../fonts/bbc-nassim-regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Nassim-bold';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/bbc-nassim-bold.eot');
  src: url('../fonts/bbc-nassim-bold.woff') format('woff'),
       url('../fonts/bbc-nassim-bold.ttf') format('truetype');
}
*/
// // Gotham Font
// @font-face {
//   font-family: 'Gotham Light';
//   src: url('../fonts/gotham/Gotham-Light.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/gotham/Gotham-Light.otf')  format('opentype'),
// 	     url('../fonts/gotham/Gotham-Light.woff') format('woff'),
//        url('../fonts/gotham/Gotham-Light.ttf')  format('truetype'),
//        url('../fonts/gotham/Gotham-Light.svg#Gotham-Light') format('svg');
//        font-weight: normal;
//        font-style: normal;
// }
// @font-face {
//   font-family: 'Gotham Book';
//   src: url('../fonts/gotham/Gotham-Book.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/gotham/Gotham-Book.otf')  format('opentype'),
//        url('../fonts/gotham/Gotham-Book.woff') format('woff'),
//        url('../fonts/gotham/Gotham-Book.ttf')  format('truetype'),
//        url('../fonts/gotham/Gotham-Book.svg#Gotham-Book') format('svg');
//        font-weight: normal;
//        font-style: normal;
// }
// @font-face {
//   font-family: 'Gotham Medium';
//   src: url('../fonts/gotham/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/gotham/Gotham-Medium.otf')  format('opentype'),
//        url('../fonts/gotham/Gotham-Medium.woff') format('woff'),
//        url('../fonts/gotham/Gotham-Medium.ttf')  format('truetype'),
//        url('../fonts/gotham/Gotham-Medium.svg#Gotham-Medium') format('svg');
//        font-weight: normal;
//        font-style: normal;
// }
// @font-face {
//   font-family: 'Gotham Bold';
//   src: url('../fonts/gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/gotham/Gotham-Bold.otf')  format('opentype'),
//        url('../fonts/gotham/Gotham-Bold.woff') format('woff'),
//        url('../fonts/gotham/Gotham-Bold.ttf')  format('truetype'),
//        url('../fonts/gotham/Gotham-Bold.svg#Gotham-Bold') format('svg');
//        font-weight: normal;
//        font-style: normal;
// }

// // MinionPro Font
// @font-face {
//   font-family: 'MinionPro-Bold';
//   src: url('../fonts/minion/MinionPro-Bold.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/minion/MinionPro-Bold.otf')  format('opentype'),
// 	     url('../fonts/minion/MinionPro-Bold.woff') format('woff'),
//        url('../fonts/minion/MinionPro-Bold.ttf')  format('truetype'),
//        url('../fonts/minion/MinionPro-Bold.svg#MinionPro-Bold') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'MinionPro-It';
//   src: url('../fonts/minion/MinionPro-It.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/minion/MinionPro-It.otf')  format('opentype'),
// 	     url('../fonts/minion/MinionPro-It.woff') format('woff'),
//        url('../fonts/minion/MinionPro-It.ttf')  format('truetype'),
//        url('../fonts/minion/MinionPro-It.svg#MinionPro-It') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'MinionPro-MediumIt';
//   src: url('../fonts/minion/MinionPro-MediumIt.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/minion/MinionPro-MediumIt.otf')  format('opentype'),
// 	     url('../fonts/minion/MinionPro-MediumIt.woff') format('woff'),
//        url('../fonts/minion/MinionPro-MediumIt.ttf')  format('truetype'),
//        url('../fonts/minion/MinionPro-MediumIt.svg#MinionPro-MediumIt') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'MinionPro-Regular';
//   src: url('../fonts/minion/MinionPro-Regular.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/minion/MinionPro-Regular.otf')  format('opentype'),
// 	     url('../fonts/minion/MinionPro-Regular.woff') format('woff'),
//        url('../fonts/minion/MinionPro-Regular.ttf')  format('truetype'),
//        url('../fonts/minion/MinionPro-Regular.svg#MinionPro-Regular') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }
