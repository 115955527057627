// Modal helpers
.modal, .modal-video, .modal-audio {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  /*background-color: #0000004f;*/
  background-image: url('../images/modal/modal-bg.svg');
  background-size: cover;
  z-index: 100;

  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1, h2, h3, h4, h5, p, label {
    color: white;
  }

  /* Church Modifications*/
  .navbar-brand {
    font-size: 30px;
    color: $darkgray;
    display: block;
    text-indent: 140%;
    overflow: hidden;
    background: url('../images/safar_logo.svg') no-repeat center center;
    background-size: auto 90%;
    background-repeat: no-repeat;
    height: 90px * .7;
    width: 100px * .7;
    padding: 0;
    /*float: left;*/
    filter: invert(100%) brightness(250%);
    width: 100%;
    display: block;    
    margin-bottom: 10px;
    @include tablet-portrait {
      margin-bottom: 20px;
      background-size: auto 100%;
    }
    
  }

  #church-data-wrapper{
    display: inline-block;
    width: 100%;    
    float: none;
    text-align: center;
    padding-left: 0;
    margin-bottom: 30px;

    @include tablet-portrait {
      margin-bottom: 50px; 
    }

    #church-display-name, #church-web-url { 
      color: $white;
    }

    #church-display-name {
      line-height: normal;
      font-weight: 400;
      font-size: 0.9em;
      @include tablet-portrait {
        font-size: 1.05em;
      }
    }
  }

  /* End - Church Modifications */ 

  .modal-dialog {
      width: 100%;
      max-width: 300px;
      background-color: rgba(0, 0, 0, .65);
      @include box-shadow(3px 3px 12px rgba(0, 0, 0, .3));
      @include border-radius(10px);
      @include tablet-portrait {
        max-width: 550px;
        transform: scale(0.9);
      }
      @include medium-desktop {
        transform: scale(1);
      }
  }

  .modal-header {
    .close {
      font-size: 1.4em;
    }
  }

  .modal-content {
     border: none;
     font-size: 20px;
     line-height: 30px;
     @include border-radius(5px);

     .modal-body {
       position: relative;
       padding: 15px;
       padding-top: 0;
       padding-bottom: 30px;
       text-align: center;
       color: $white;
       @include tablet-portrait {
         padding-bottom: 40px;
       }

     }
     .modal-header {
       min-height: auto;
       padding: 15px 15px 0;
       border-bottom: none;
       text-align: left;
       padding-top: 10px;
     }
     a {
       font-size: 20px;
       color: $white;
       text-decoration: underline;
     }
     .close {

       top: -15px;
       left: -15px;
       background-color: transparent!important;
       text-indent: -99999px;
       padding: 0;
       margin: 0;
       height: 30px;
       width: 30px;
       background-image: url('../images/modal/modal-close.svg');
       background-size: 50%;
       background-position: center center;
       background-repeat: no-repeat;
       border: none;

       @include tablet-portrait {
         height: 50px;
         width: 50px;
       }

       /*font-family: $Gotham-Book;
       font-style: normal;
       text-shadow: unset;
       color: $white;
       opacity: 1;
       font-weight: 300;
       background-color: transparent;
       border: 0;*/
       .sr-only {
           display: none;
       }
     }

     p {
       margin-top: 0;
     }

    #button-modal-confirm, #button-modal-cancel {
      color: $white;
      text-align: center;
      padding: .6em 2.6em;
      box-shadow: none;
      cursor: pointer;
      outline: none;
      @include border-radius(34px);
      font-weight: bold;
      margin: 0 0.6em;
      font-family: $Gotham-Book;
      font-style: normal;
    }

     #button-modal-confirm {
        background-color: #F44343;
        border: 3px solid #F44343;
     }

     #button-modal-cancel {
       background-color: transparent;
       border: 3px solid $white;

       margin: 0 0.6em;
        padding: 0.6em 2.6em;
       margin-bottom: 1.2em;

       background-color: transparent !important;
        text-indent: inherit;
        height: auto;
        width: auto;
        background-image: unset;
        background-size: unset;
        background-position: unset;
        background-repeat: unset;
        
     }

     #modal-title {
      font-size: 45px;
      letter-spacing: -1.35px;
      margin-bottom: 15px;
      @include tablet-portrait {
        font-size: 90px;
        letter-spacing: -0.5px;
        margin-bottom: 10px;
      }
     }

     // START LOGIN MODAL STYLES

     // START - Divider

      .divider {
        display: flex;

      &:before,
        &:after {
          content: "";
          flex: 1;
        }
      }

      // Base line
      .line {
        align-items: center;
        max-width: 408px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 20px;
        letter-spacing: -0.35px;
        font-family: $Gotham-Medium;
        line-height: 20px;
        font-size: 18px;
        @include tablet-portrait {
          line-height: 30px;
          font-size: 25px;
          letter-spacing: -0.5px;
        }

        &:before,
        &:after {
          height: 2px;
        }
        &:before {
          margin-right: 1.5em;
          @include tablet-portrait {
            margin-right: 1em;
          }
        }
        &:after {
          margin-left: 1.5em;
          @include tablet-portrait {
            margin-left: 1em;
          }
        }
      }

      .one-line {
        &:before,
        &:after {
          background: #FFFFFF;
        }
      }

      // END - Divider

     .hybridauth-widget-wrapper ul.hybridauth-widget li {
       display: block;
     }

     a {
       text-decoration: none;
     }

     .item-list {
       a {
        span {
          padding: 10px;
          opacity: 1;
          margin: 0 auto;
          margin-bottom: 12px;
          padding: 11px;
          float: none;
          width: auto;
          height: auto;
          background: unset;
          max-width: 408px;
          letter-spacing: -0.35px;
          font-family: $Gotham-Medium;
          line-height: 20px;
          font-size: 18px;
          @include tablet-portrait {
            padding: 15px;
            line-height: 30px;
            font-size: 25px;
            letter-spacing: -0.5px;
            margin-bottom: 15px;
          }
          @include border-radius(34px);

          &.facebook {
            background: url('../images/modal/facebook_icon.svg') no-repeat 5px center;
            background-color: #1977F3;
            background-size: 35px;
            @include tablet-portrait {
              background: url('../images/modal/facebook_icon.svg') no-repeat 15px center;
              background-color: #1977F3;
              background-size: 45px;
            }
          }
          &.google {
            color: #4285F4;
            margin-bottom: 20px;
            background: url('../images/modal/google_g_icon.svg') no-repeat 5px center;
            background-color: #FFFFFF;
            background-size: 30px;
            @include tablet-portrait {
              background: url('../images/modal/google_g_icon.svg') no-repeat 15px center;
              background-color: #FFFFFF;
              background-size: 40px;
              margin-bottom: 20px;
            }
          }
        }
       }
     }

     #with-email {
      background-color: transparent;
      background: url('../images/modal/email_icon.svg') no-repeat 10px center;
      background-size: 27px;
      opacity: 1;
      margin: 0 auto;
      margin-bottom: 20px;
      padding: 9px;
      width: 100%;
      display: block;
      max-width: 408px;
      letter-spacing: -0.35px;
      border: 2px solid #FFFFFF;
      font-family: $Gotham-Medium;
      @include border-radius(34px);
      line-height: 20px;
      font-size: 18px;
      @include tablet-portrait {
        padding: 15px;
        background: url('../images/modal/email_icon.svg') no-repeat 15px center;
        background-size: 37px;
        line-height: 30px;
        font-size: 25px;
        letter-spacing: -0.5px;
      }
     }

  }

  #safar-invite-helper-form {
    display: none;

    .form-item-email {
      margin-top: 0;
      label {
        margin-bottom: 10px;
        font-family: $Gotham-Medium;
        line-height: 20px;
        font-size: 18px;
        letter-spacing: -0.35px;
        @include tablet-portrait {
          line-height: 30px;
          font-size: 25px;
          letter-spacing: -0.5px;
        }
        .form-required {
          display: none;
        }
      }
      input.form-text {
        padding: .35em .5em;
        font-size: 18px;
        line-height: 28px;
        @include tablet-portrait {
          padding: .6em .7em;
          font-size: 22px;
          line-height: 28px;
        }
      }
    }

    #edit-submit {
      float: none;
      color: #FFFFFF;
      background-color: #36CE7C;
      width: 100%;
      max-width: 408px;
      font-family: $Gotham-Medium;
      line-height: 20px;
      font-size: 18px;
      letter-spacing: -0.35px;
      @include tablet-portrait {
        line-height: 30px;
        font-size: 25px;
        letter-spacing: -0.5px;
      }
      @include border-radius(34px);
    }

    .messages {
      max-width: 408px;
      margin: 0 auto;
      margin-bottom: 15px;
    }

    div.error {
      background-image: none;
      background-color: #9c2323;
      color: #fff;
      @include border-radius(5px);
      font-size: 14px;
      padding: 5px;
      line-height: 20px;
      @include tablet-portrait {
        padding: 10px;
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 18px;
      }
    }

  }

  #success-ajax {
    padding: 5px;
    padding-bottom: 30px;
    padding-top: 20px;
    @include tablet-portrait {
      padding-bottom: 50px;
      padding-top: 40px;
    }
    h1 {
      font-size: 25px;
      letter-spacing: -1.35px;
      margin-bottom: 10px;
      @include tablet-portrait {
        font-size: 60px;
        letter-spacing: -0.5px;
        margin-bottom: 15px;
      }
    }
    p {
      font-family: $Gotham-Medium;
      line-height: 20px;
      font-size: 14px;
      letter-spacing: -0.35px;
      margin-bottom: 0;
      @include tablet-portrait {
        line-height: 30px;
        font-size: 25px;
        letter-spacing: -0.5px;
      }
    }
  }

  .ajax-progress {
    display: block;
    width: 100%;
    margin: 0 auto;
    float: none;

    .throbber {
      margin-top: 10px;
      float: none;
      display: inline-block;
      vertical-align: middle;
      height: 30px;
      width: 30px;
      @include tablet-portrait {
        height: 50px;
        width: 50px;
      }
      background-image: url('../images/modal/loading-ajax.gif');
      background-repeat: no-repeat !important;
      background-position: center center !important;
      background-size: 100% 100% !important;
    }

    .message {
      display: none;
    }
  }

  &.fade.in  {
    display:flex!important;
    flex-direction:column;
    justify-content:center;
    align-content:center;
    align-items: center;
  }

}

.modal-video {
.modal-dialog {
    position: relative;
    max-width: 300px;
    @include tablet-portrait {
      max-width: 550px;
      transform: scale(0.9);
    }
    @include medium-desktop {
      max-width: 1124px;
      transform: scale(1);
    }
}
.close {
  position: absolute;
  top: -15px;
  left: -15px;
  background-color: #000000d9!important;
  border-radius: 100px;
  text-indent: -99999px;
  padding: 0;
  margin: 0;
  height: 40px;
  width: 40px;
  background-image: url('../images/modal/modal-close.svg');
  background-position: center center;
  background-repeat: no-repeat;

  background-size: 30%!important;
  border-radius: 100px 0 0 100px;
  border: 2px solid #000!important;
  border-right: none!important;
  left: -25px !important;
  top: 0px !important;
  width: 30px !important;
  height: 25px !important;
  padding: 9px !important;
  padding-right: 5px !important;
  filter: brightness(0);

  @include tablet-portrait {
    left: -35px!important;
    top: 15px!important;
    width: 30px!important;
    height: 30px!important;
    padding: 15px!important;
    padding-right: 20px!important;
  }

  @include small-desktop {
    left: -45px !important;
    top: 15px !important;
    width: 45px !important;
    height: 44px !important;
    padding: 15px !important;
    padding-right: 20px !important;
  }

}

.modal-header {
  padding-top: 0px!important;
}
.modal-body {
  padding: 0px!important;
  padding-bottom: 0px!important;
}
}

.modal-video, .modal-audio {
position: absolute;
.modal-dialog {
  background-color: rgba(0, 0, 0, .80);
}
}

.modal-audio {

.modal-header {
  padding-top: 20px!important;
}

.modal-body {
  padding: 30px!important;
  padding-top: 10px!important;
  padding-bottom: 20px!important;
  @include tablet-portrait {
    padding: 50px!important;
    padding-top: 10px!important;
    padding-bottom: 50px!important;
  }
}

.modal-dialog {
  max-width: 333px;
  height: auto;
  @include tablet-portrait {
    max-width: 600px;
    transform: scale(0.9);
  }
  @include medium-desktop {
    max-width: 600px;
    transform: scale(1);
  }

  h2, h3 {
    text-align: left;
    padding: 0;
    margin: 0;
  }

  h2 {
    font-size: 39px;
    letter-spacing: -.04em;
    @include tablet-portrait {
      font-size: 80px;
      letter-spacing: -.04em;
      line-height: 1em;
    }
    font-weight: normal;
    font-family: $MinionPro-Regular;
  }

  h3 {
    font-size: 20px;
    letter-spacing: -1.2px;
    line-height: 1.65em;
    @include tablet-portrait {
      font-size: 30px;
      letter-spacing: -1.2px;
      line-height: 1.65em;
    }
    font-family: $Gotham-Book;
    color: #CBC6BF;
    font-weight: 300;
  }
}

.mejs__container {
  background-color: transparent;
  height: 65px!important;
  width: 100%!important;
  margin-top: 10px;
  @include tablet-portrait {
    margin-top: 40px;
  }
}

.mejs__controls {
  background: none;
  height: 65px;
  padding: 0;
}

.mejs__time-current {
  background-color: #36CE7C;
}

.mejs__currenttime, .mejs__currenttime-container {
  display: none;
}

.mejs__currenttime, .mejs__duration {
  font-size: 12px;
  line-height: 25px;
  font-family: $Gotham-Book;
  font-weight: 300;
  letter-spacing: -0.64px;
  @include tablet-portrait {
    font-size: 16px;
  }
}

.mejs__time-buffering, .mejs__time-current, .mejs__time-float-current, .mejs__time-hovered, .mejs__time-loaded, .mejs__time-marker, .mejs__time-total{
  height: 7px;
  border-radius: 50px;
}

.mejs__time-total {
  top: 21px;
  background: #FFFFFF;
}

.mejs__time-loaded {
  background: #FFFFFF;
}

.mejs__time {
  line-height: 65px;
  height: 65px;
  padding: 0 10px;
}

.mejs__time-handle-content {
  border: 8px solid #36CE7C;
  left: -7px;
  top: -9px;
  transform: scale(1);
}

.mejs__time-rail {
  height: 65px;
}

.mejs__playpause-button {
  border: 5px solid #fff;
  width: 65px;
  height: 65px;
  border-radius: 100px;
  margin-right: 0px;
  transform: scale(0.8);
  transform-origin: left;

  @include tablet-portrait {
    transform: scale(1);
    transform-origin: unset;
    margin-right: 10px;
  }

  button {
    margin: 18px 18px;
  }
}

.mejs__volume-button, .mejs__horizontal-volume-slider {
  display: none;
}

/*.audio-player {
  background: white;
  border: 1px solid lighten(#acacac, 20%);
  text-align: center;
  display: flex;
  flex-flow: row;
  margin: 4rem 0 4rem 0;
  .album-image {
    min-height: 100px;
    width: 110px;
    background-size: cover;
  }
  .player-controls {
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
    flex: 3;
    progress {
      width: 90%;
    }
    progress[value] {
      -webkit-appearance: none;
      appearance: none;
      background-color: white;
      color: blue;
      height: 5px;
    }
    progress[value]::-webkit-progress-bar {
      background-color: white;
      border-radius: 2px;
      border: 1px solid lighten(#acacac, 20%);
      color: blue;
    }
    progress::-webkit-progress-value {
      background-color: blue;
    }
    p {
      font-size: 1.6rem;
    }
  }
  #play-btn {
    background-image: url('http://www.lukeduncan.me/images/play-button.png');
    background-size: cover;
    width: 75px;
    height: 75px;
    margin: 2rem 0 2rem 2rem;
    &.pause {
      background-image: url('http://www.lukeduncan.me/images/pause-button.png');
    }
  }
}*/
}






// Eu Compliance Cookie popup

#sliding-popup {

  width: 100%;
  height: 250px;

  @include medium-desktop {
    height: 300px;
  }  

  .eu-cookie-compliance-banner {    
    width: 100%;
    height: 100%;
  }

  .popup-content {
    margin: 0 auto;
    max-width: 100%;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;    
    justify-content: center;
    flex-direction: row;
    padding: 0 6%;
    flex-direction: column;

    @include small-desktop {      
      flex-direction: row;
      justify-content: space-around;
    }

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.67;
      background-image: url('../images/popup/popup-bg.jpg');
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover; 
    }

    h2, p {
      color: $white;
      margin: 0;
      padding: 0;
    }

    h2 {
      font-family: $MinionPro-Bold;
      font-weight: 600;
      letter-spacing: -1.4px;
      font-size: 1.58em;
      line-height: 1em;
      margin-bottom: 5px;
      text-align: center;

      @include small-desktop {
        text-align: left;
      }

      @include medium-desktop {
        font-size: 1.68em;
        line-height: normal;
        margin-bottom: 0;
      }
    }

    p {
      font-family: $Gotham-Book;
      font-weight: 300;
      letter-spacing: -1px;      
      font-size: 1.13em;
      text-align: center;

      @include small-desktop {
        text-align: left;
        padding-top: 10px;
      }

      @include medium-desktop {
        font-size: 1.20em;
      }
    }


    #popup-text, #popup-buttons {
      position: relative;
      z-index: 2;
      max-width: unset;
      display: flex;
      flex-direction: column;
    }
    
    #popup-text {      
      margin-bottom: 25px;

      @include small-desktop {
        flex-direction: column;
        margin-bottom: 0;
      }
    }

    #popup-buttons{
      
      @include small-desktop {
        flex-direction: row;
      }

      button {
        display: inline-block;
        border: 3px solid #000;
        padding: 8px;
        font-size: 1.05em;
        letter-spacing: -0.77px;
        font-weight: 400;
      }

      .find-more-button {
        color: #000;
      }

      .agree-button{
        margin-bottom: 10px;

        @include small-desktop {
          margin-right: 20px;
          margin-bottom: 0px;
        }
      }
    }


  }

}




// RTL
html[dir="rtl"] {

  .modal {

    .line {
      &:before {
        margin-left: 1.5em;
        @include tablet-portrait {
          margin-left: 1em;
        }
      }
      &:after {
        margin-right: 1.5em;
        @include tablet-portrait {
          margin-right: 1em;
        }
      }
    }

  }

  .modal-video {
    .close {
      right: -15px;
      background-size: 30%!important;
      border-radius: 0 100px 100px 0;
      border-right: 2px solid #000 !important;
      border-left: none!important;
      right: -25px !important;
      top: 0px !important;
      width: 30px !important;
      height: 25px !important;
      padding: 9px !important;
      padding-left: 5px !important;

      @include tablet-portrait {
        right: -35px!important;
        top: 15px!important;
        width: 30px!important;
        height: 30px!important;
        padding: 15px!important;
        padding-left: 20px!important;
      }

      @include small-desktop {
        right: -45px !important;
        top: 15px !important;
        width: 45px !important;
        height: 44px !important;
        padding: 15px !important;
        padding-left: 20px !important;
      }

    }
  }


  .modal-audio {

    .modal-content .modal-header {
      text-align: right;
    }

    .modal-dialog {

      h2, h3 {
        text-align: right;
        padding: 0;
        margin: 0;
      }

      h2 {
        font-size: 39px;
        letter-spacing: 0.1px;
        @include tablet-portrait {
          font-size: 80px;
          line-height: 1em;
        }
        font-weight: normal;
        font-family: $MjEgypt;
      }

      h3 {
        font-size: 20px;
        letter-spacing: 0;
        line-height: 1.65em;
        @include tablet-portrait {
          font-size: 30px;
          line-height: 1.65em;
        }
        font-family: $estedad-Medium;
        color: #CBC6BF;
        font-weight: 300;
      }
    }

  }


  #sliding-popup .popup-content #popup-buttons .agree-button {
    @include small-desktop {
      margin-left: 20px;
    }
  }

}
