html[dir="rtl"] {

    .i18n-fa{
      /* Journey */
      .step-def-title h2,
      .field-name-field-title h2,
      .field-name-field-title .field-item {
        font-family: $MjEgypt !important;
      }
    }

    .i18n-ps{
      /* Journey */
      .step-def-title h2,
      .field-name-field-title h2,
      .field-name-field-title .field-item {
        font-family: $lateef;
        font-weight: 600;
      }
    }

}