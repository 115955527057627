// Fields Styles

.form-control, .form-control:active, .form-control:hover, .form-control:visited, .form-control:focus {
  box-shadow: none;
  outline: none;
  border-color: #707070;
}

.control-label {
  color: $black;
  font-family: $Gotham-Medium;
}

input {
  letter-spacing: -.04em;
  @include tablet-portrait {
  }
  @include small-desktop {
  }
}

.form-item {
  margin: .8em 0;
  @include tablet-portrait {
    margin: 1.4em 0;
  }
}


input,
input.form-text {
  font-family: $Gotham-Book;
  border-color: #a2a0996b;
  border-width: 1px;
  color: $black;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: .6em .7em;
  text-align: center;
  max-width: 280px;
  margin: 0 auto .3em;

  @include tablet-portrait {
    padding: .7em 1em;
    max-width: 100%;
  }

  @include small-desktop {
  }
}

.form-submit {
  @include button;
}

.form-submit:hover,
.form-submit:active:hover,
.form-submit:active:focus,
.form-submit:active.active,
.form-submit.active:hover,
.form-submit.active:focus,
.form-submit.active.active {
  background-color: $darkgray;
}

.not-front .form-submit {
  /*padding-right: 54px;*/
}

.draggable a.tabledrag-handle {
  margin-left: 0;
  .handle {
    padding: 0 10px;
    margin: 0;
  }
}

a.tabledrag-handle-hover .handle {
  background-position: 6px -11px;
}

.page-header {
  color: $black;
  text-align: center;
}

// RTL

html[dir="rtl"] {

  input,
  input.form-text {
    font-family: $estedad-Medium;
    padding: .6em .7em;
    @include tablet-portrait {
      /*padding: 0 1em;*/
      padding: .15em 1em;
    }
  }


  .form-submit {
    padding-left: 2em;
    padding-right: 2em;
  }

}
