// Frontpage style

#navbar {
  position: relative;
  z-index: 100;
}

// .main-container {
//   padding: 0;
//   .row {
//     margin: 0;
//   } 
// }

#link-to-wis:hover {
  text-decoration: none;
}

#Section-Start {

  .startslide {
    .field-name-title-field {
      display: none;
    }

    .paragraphs-section {
      padding: 0;
    }
  }

}

#what-is-safar-container {
  text-align: center;
  width: 100%;
  height: 50%;
  position: relative;
  margin: 0 auto;

  @include tablet-portrait {
    width: 400px;
    height: 310px;
  }
  @include big-desktop {
    /*width: 600px;
    height: 440px;*/    
  }

  #home-text-container {
    position: absolute;
    z-index: 100;
    width: 100%;
    margin-top: -1.8em;

    @include tablet-portrait {
      padding-top: 20px;
      top: 50px;
      margin-top: 0;
    }
  }

  #what-is-safar-what-is,
  #what-is-safar-safar {
    color: #000;
    margin: 0;
    a {
      font-family: $MinionPro-Regular;
      color: #000;
      text-decoration: none;
    }
  }

  #what-is-safar-what-is {
    font-size: 37px;
    letter-spacing: -2px;
    line-height: 28px;
    text-align: left;
    padding-left: 18%;
    a {
      color: #54524C;
    }

    @include tablet-portrait {
      padding-left: 120px;
      font-size: 70px;
      line-height: 45px;
    }
  }

  #what-is-safar-safar {
    font-family: $MinionPro-Regular;
    font-size: 90px;
    line-height: 65px;
    padding-bottom: 3px;
    color: $black;
    font-weight: 500;
    letter-spacing: -3px;

    @include tablet-portrait {
      padding-bottom: 20px;
      font-size: 180px;
      line-height: 140px;
      background-size: 85px;
      letter-spacing: -6px;
    }
  }

  #what-is-safar-arrow {
    height: auto;
    width: 55px;
    margin-left: 35%;
    position: absolute;
    top: 40%;
    z-index: 99;
    right: 10%;

    @include tablet-portrait {
      position: absolute;
      width: 85px;
      margin-left: 0;
      top: 35%;
      right: 5%;
    }
    @include tablet-landscape {
      right: 0;
    }
  }

  #what-is-safar-ribbon {
    padding-right: 0;
    width: 70%;
    padding-right: 0;
    padding-top: 0;
    position: relative;
    margin-left: -10px;
    @include small-desktop {
      margin-left: -35px;
    }
    @include tablet-portrait {      
      padding-top: 65px;
      width: 100%;
    }
  }
}

#homepage-buttons-container{
  margin-top: 60px;
  
  h3 {
      bottom: 140px;
      font-family: $Gotham-Medium;
      text-align: center;
      font-size: 1.5em;
      width: 100%;
      position: absolute;
      margin: 0;
      padding: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      font-weight: 500;

      @include small-desktop{
          font-size: 1.5em;
          letter-spacing: 10px;
      }
  }

  @include small-desktop{
      margin-top: 40px;
      h3{
          bottom: 140px;
      }
  }
  @include big-desktop{
      margin-top: 140px;
      h3{
          bottom: 240px;
      }
  }

  ul{
      margin: 0;
      padding: 0;
      list-style: none;

      li{
          padding: 0;
          margin-bottom: 10px;

          a{
              padding: 0.4em;
              margin: 0 auto;
              font-family: $Gotham-Medium;
              font-weight: 500;
              font-size: 1em;
              border: 3px solid #000000;
              @include border-radius(40px);
          }
      }
  }
}

// RTL Frontpage
html[dir="rtl"] {

  #what-is-safar-container {

    #what-is-safar-what-is a {
      font-family: $estedad-Medium;
    }

    #what-is-safar-safar a {
      font-family: $MjEgypt;
    }

    #what-is-safar-arrow {

      left: 0;
      margin-left: 6%;
      position: absolute;
      top: 20%;
      right: unset;

      @include tablet-portrait {
        height: auto;
        width: 85px;
        margin-left: 0;
        top: 26%;
        z-index: 99;
      }
    }

  }


  #homepage-buttons-container{    
    h3 {
        font-family: $estedad-Bold;
  
        @include small-desktop{
            font-size: 1.5em;
            letter-spacing: 0px;
        }
    }
    ul li a{     
      font-family: $estedad-Light;
    }    

  }
}