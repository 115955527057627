//Menus Styles

/* Hide Danish */

.language-switcher-locale-url .da{
  display: none;  
} 


/* rtl:begin:ignore */
#menu {
  display: none;
  position: absolute;  
  background: url(../images/new-design/menu_ribbon.svg) no-repeat;
  background-position: 30px 0;
  padding: 69px 16px 0 32px;
  min-height: 550px;
  min-width: 260px;
  right: -94px;
  top: -24px;
  background-position: center;

  transform: scale(0.8);

  @include small-desktop {
    transform: scale(1);
    right: -88px;
    top: 33px;  
  }
  @include medium-desktop {  
    right: -84px;
    top: 39px;
  }

  nav {
    margin-left: -55px;
  }

  &.active {
    display: block;
  }

  @include border-radius(4px);

  ul, li {
    text-align: center;
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  ul:first-child {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  li {
    padding: 0;
    a {
      color: $white;
      display: block;
      line-height: 31px;
      font-size: 20px;
      font-family: $Gotham-Book;
      font-weight: 300;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .menu:nth-child(1) {
    li.first a {
      font-family: $Gotham-Bold;
      font-weight: 600;
    }
  }

  .menu:nth-child(2) { 
    margin-top: 100px;
  }

  ul.language-switcher-locale-url {
    li {
      display: inline-block;
      a {
        font-family: $Gotham-Medium;
      }
    }
    .en {
      float: left;
    }
    .fa {
      a {
        font-family: $estedad-Medium;
      }
    }
    li.active {
      a {
        text-decoration: underline;
      }
    }
  }

}

/* Hide The Steps menu item from logged in users */
.logged-in #menu li:nth-child(3) {
  display: none;
}


/* rtl:end:ignore */

html[dir="rtl"] {

  #navbar {
    #menu {
      padding-right: 24px;   
    }
  }

  #menu{

    li {
      a {
        font-family: $estedad-Medium;
      }   
    }
  
    .menu:nth-child(1) {
      li.first a {
        font-family: $estedad-Medium;
      }
    }

  }

}



/* OLD - Language Switcher */

#language-switcher {
  /*display: inline-block;
  margin-right: 5px;
  
  .langswitcher-toggle {
    display: none;
  }
  
  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
    text-indent: 0;
  }
  
  li a {
    display: inline-block;
    height: 55px * .7;
    width: 55px * .7;
    text-indent: 200px;
    overflow: hidden;
    background: url("/sites/all/themes/safar_rtl/images/new-design/languageicon_EN.svg") no-repeat center center;
    background-size: 84% 84%;        
          
    @include tablet-portrait {
      height: 55px * .8;
      width: 55px  * .8;
    }
    @include small-desktop {
      height: 55px * .8;
      width: 55px * .8;
    }
    @include big-desktop {
      height: 55px;
      width: 55px;
    }
  
  }
  
  li.en a {
  background: url("/sites/all/themes/safar_rtl/images/new-design/languageicon_FA.svg") no-repeat center center;
  background-size: 84% 84%;        
  }    
  
  li.active {
  display: none;
  }*/
  
}



.language-switcher-locale-url { 
  /*text-align: center;
  margin-top: -40px;
  position: absolute;
  width: 100%;
  font-size: 1.3em;
  padding-left: 0;
  padding-right: 0;

  li {
    display: inline-block;

    @include tablet-portrait {
      margin: 0 15px;
    }

  }

  .fa {
    a {
      font-family: $estedad-Medium;
      font-size: 1em;
      @include tablet-portrait {
        font-size: 22px;
      }
    }
  }

  .en {
    a {
      font-size: 0.9em;
      @include tablet-portrait {
        font-size: 22px;
      }
    }
  }

  li a {
    padding: 5px 10px;
    text-transform: capitalize;
    display: block;
    color: $black;
    font-weight: 500;
    font-family: $Gotham-Medium;
    text-decoration: none;
  }

  li.active {

    a {
      text-decoration: none;
      text-decoration: underline;
    }
  }*/
}



/* NEW - Language Switcher */

#language-switcher {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: unset;
  border-radius: unset;
  vertical-align: top;
  outline: none;
  position: relative;
}

.langswitcher-toggle {
  background: url('../images/world-language.svg') no-repeat center center;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  height: 38.5px;
  width: 38.5px;
  text-indent: -9999px;
  display: inline-block;
  border: none;
}

.language-switcher-locale-url{
  display: none;
  &.active{
    display: block;
    background: url('../images/new-design/menu_ribbon.svg') no-repeat;
    background-position: 30px 0;
    padding: 69px 16px 0 32px;
    min-height: 500px;
    min-width: 260px;
    position: absolute;
    right: -90px;
    top:-20px;    
    transform: scale(0.8);

    @include small-desktop {
      transform: scale(1);
      right: -88px;
      top: 33px;  
    }
    @include medium-desktop {  
      right: -70px;
      top: 40px;
    }
  }
}

ul.language-switcher-locale-url {  
  list-style: none;
  li {
    text-align: center;
    margin-bottom: 4px;
    line-height: 25px;
    .language-link{
      color: #fff;
      font-family: $Gotham-Medium;
      font-size: 20px;
    }
    .active {
      font-weight: 600;
    }
    &.fa{
      .language-link{
        font-family: $estedad-Medium;
      }      
    }
  }
}