//Footer Styles

.footer {
  padding-bottom: 5px;
  border: none;
  text-align: center;
  margin-top: 0;
  align-self: flex-end;
  width: 100%;
  font-size: 8.5px;

  @include tablet-portrait {
    font-size: .8em;
    /*padding-bottom: 2%;*/
  }

  @include small-desktop {
    font-size: .7em;
  }

  #block-block-1 {
    opacity: 0.45;
    p {
      margin-bottom: 10px;
      line-height: unset;
      color: $black;

      a {
        color: $black;
        padding: 0 5px;
        text-decoration: underline;
      }
    }
    @include tablet-portrait {
      p {
        margin-bottom: 0px;

        a {
          padding: 0 5px;
        }
      }
    }
    @include small-desktop {
      p {
        margin-bottom: 10px;

        a {
          padding: 0 5px;
        }
      }
    }
  }

}

// RTL
html[dir="rtl"] {

}
