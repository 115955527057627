// Tables stylesheets
table {

  thead > tr > th, > thead > tr > td, > tbody > tr > th, > tbody > tr > td, > tfoot > tr > th, > tfoot > tr > td {
    border: none;
    background: transparent;
  }

  thead > tr {
    border-bottom: 1px solid #ccc;
    color: $table-link;
    background: transparent;
    th {
      padding-left: 0;
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: transparent;
    }
    tr {
      background: transparent;
      height: 1.5em;
      line-height: 1.5em;
    }
    tr td {
      padding-left: 0;
      background: transparent;
    }
    tr td a {
      color: $table-link;
    }
  }

}

.tabledrag-toggle-weight-wrapper {
  display: none;
}

.draggable a.tabledrag-handle {

  line-height: 20px;
  height: 20px;
  display: inline-block;
  float: none;

  .handle {
    height: 20px;
    width: 20px;
    font-size: 2px;
    background-repeat: no-repeat;
    background-size: cover;  
  }  
}

table.table-hover{
  tbody > tr:hover {
      background-color: transparent;
  }
}

