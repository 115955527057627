//Colors
$transparent: transparent;
//Text Colors
$lightgray: #aaaaaa;
$gray: #6e6e6e;
$grayt: rgba(51,51,51,.19);
$midgray: #555555;
$darkgray: #3D3B36;
$black: #000000;
$white: #ffffff;
$pinktext: #da4880;
//Buttons
$pink: #da4880;
$pink-hover: #c13e70;
//Other
$grayline: #d1d1d1;
//Fields
$bordergray: #d1d1d1;
$inputtext: #b1b1b1;
$control-label: #555555;
//Table Colors
$table-link: #555555;
//DOTS
$disabled-dot: #6c6964;
